$mainColor-1: #ffffff;
$mainColor-2: #44abbd;
$mainColor-3: #1a4677;

$mainTextColor-1: black;
$mainTextColor-2: #1c1c1d;
$mainTextColor-3: black;

$CTA-color: #008a90;

$relegationZoneColor: rgb(255, 225, 198);
$relegationZoneBorderColor: rgb(255, 201, 151);

$eliminationColor: rgb(255, 218, 218);
$eliminationBorderColor: rgb(255, 151, 151);
