.teamLogoIcon {
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  img {
    filter: drop-shadow(0px 0px 2px white);
    border-radius: 2px;
  }

  .teamLetterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    border-radius: 1000px;

    font-weight: 500;

    box-shadow: 0px 0px 4px rgb(199, 199, 199);
  }
}

.teamLogoIcon_fillSpace {
  width: 100%;
  aspect-ratio: 1;
}
