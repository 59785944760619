@import "../../../../styles-colorLibraries/MainPagesColors.scss";

$height: 60px;
$iconFontSize: 21px;

$animationsSpeed: 300ms;

@mixin pageLink {
  display: grid;
  place-items: center;
  color: $mainTextColor-2;
  text-decoration: none;

  height: $height;
  width: 100%;

  .linkIcon {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  i {
    display: flex;
    justify-content: center;

    font-size: 22px;

    width: 100%;

    margin-top: 8px;
  }

  .material-symbols-outlined {
    text-align: center;
    font-size: 30px;

    width: 100%;
  }

  .linkText {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    font-weight: 600;

    height: 15px;

    margin-bottom: 5px;
  }
}

@mixin dropDownBtn {
  display: grid;
  color: white;
  text-decoration: none;

  height: $height;
  width: 100%;

  .linkIcon {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  .fa-solid {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  .cartTotalItems {
    position: absolute;
    text-align: center;

    color: $mainColor-1;

    width: 20px;

    font-size: 12px;
    font-weight: 700;

    margin-left: 31px;
    margin-top: 7px;
  }

  i {
    display: flex;
    justify-content: center;

    font-size: 18px;

    width: 100%;

    margin-top: 8px;
  }

  .linkText {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    height: 15px;

    margin-bottom: 5px;
  }
}

.mobileFooter {
  display: flex;
  justify-content: space-between;

  position: fixed;
  bottom: 0;

  background-color: $mainColor-1;
  box-shadow: 0px -5px 8px rgba(0, 0, 0, 0.2);

  height: $height;
  width: 100%;

  .activePageLink {
    @include pageLink();

    background-color: $mainColor-2;
  }

  .pageLink {
    @include pageLink();
  }
}

.extraSpacing {
  height: $height + 5;
}

.myBusinessMoreBtnContainer {
  width: 100%;

  .pageLink {
    @include pageLink();
  }

  .activePageLink {
    @include pageLink();

    background-color: $mainColor-2;
  }

  .dropDownContent {
    margin-bottom: 200px;
    color: red;
  }
}

.dropDownContent {
  position: absolute;
  bottom: -$height - 105px;

  transition: height $animationsSpeed ease;
  width: 250px;
  transform: translateX(-45%);

  background-color: $mainColor-1;
  color: white;
  border: 1px solid rgb(8, 96, 20);

  border-radius: 7px;

  padding: 1rem;

  overflow: hidden;

  .menuPrimary-enter {
    position: absolute;
    transform: translateX(-110%);
  }
  .menuPrimary-enter-active {
    transform: translateX(0%);
    transition: all $animationsSpeed ease;
  }
  .menuPrimary-exit {
    position: absolute;
  }
  .menuPrimary-exit-active {
    transform: translateX(-110%);
    transition: all $animationsSpeed ease;
  }

  .menuSecondary-enter {
    transform: translateX(110%);
  }
  .menuSecondary-enter-active {
    transform: translateX(0%);
    transition: all $animationsSpeed ease;
  }
  .menuSecondary-exit {
  }
  .menuSecondary-exit-active {
    transform: translateX(110%);
    transition: all $animationsSpeed ease;
  }

  @mixin DropDownItem($iconBtnBGC) {
    display: flex;
    align-items: center;

    color: white;
    text-decoration: none;

    height: 40px;

    border-radius: 5px;

    transition: background-color 100ms;

    padding: 5px;

    .leftSection {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .iconBtn {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $iconBtnBGC;
        transition: background-color 100ms;

        max-height: 30px;
        max-width: 30px;
        min-height: 30px;
        min-width: 30px;

        border-radius: 50%;

        margin-right: 3px;
      }
    }

    .iconRight {
      display: none;
    }
  }

  .dropDownItem {
    @include DropDownItem($mainColor-2);
  }

  .activeDropDownItem {
    @include DropDownItem(transparent);
    background-color: $mainColor-2;
  }

  .dropDownItem:hover {
    background-color: $mainColor-2;

    .iconBtn {
      background-color: rgba(255, 255, 255, 0.388);
    }
  }
}
