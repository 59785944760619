$green_mainColor-1: #ffffff;
$green_mainColor-2: #43b239;
$green_mainColor-3: #68c95f;

$green_mainColor-2-hover: #1cdb3644;

$green_mainTextColor-1: rgb(0, 0, 0);
$green_mainTextColor-2: rgb(255, 255, 255);
$green_mainTextColor-3: #31b625;

$green_CTA-color: #fd8b09;

$green_relegationZoneColor: rgb(255, 225, 198);
$green_relegationZoneBorderColor: rgb(255, 201, 151);

$green_eliminationColor: rgb(255, 218, 218);
$green_eliminationBorderColor: rgb(255, 151, 151);
