$animTime: 2s;

.splashScreen {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 100000;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #1a1a1a;
  color: white;
  opacity: 0;

  height: 100vh;
  width: 100vw;

  @keyframes SplashFadeOut {
    0% {
      opacity: 1;
    }

    90% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  animation-name: SplashFadeOut;

  animation-duration: $animTime;
  animation-iteration-count: 1;

  .logoAndTitleContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .myLogoImg {
      @keyframes SplashImgGrow {
        0% {
          height: 120px;
          opacity: 0;
        }

        30% {
          height: 200px;
          opacity: 1;
        }

        85% {
          height: 200px;
          opacity: 1;
        }

        100% {
          height: 120px;
          opacity: 0;
        }
      }

      height: 120px;
      aspect-ratio: 1;
      opacity: 0;
      object-fit: cover;

      animation-name: SplashImgGrow;

      animation-duration: $animTime;
      animation-iteration-count: 1;
    }

    .splashTitle {
      @keyframes SplashTextGrow {
        0% {
          font-size: 16px;

          opacity: 0;
        }

        30% {
          font-size: 26px;

          opacity: 1;
        }

        85% {
          font-size: 26px;

          opacity: 1;
        }

        100% {
          font-size: 16px;

          opacity: 0;
        }
      }

      text-align: center;

      font-weight: 500;
      opacity: 0;

      animation-name: SplashTextGrow;

      animation-duration: $animTime;
      animation-iteration-count: 1;
    }
  }
}
