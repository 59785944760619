$mainColor-1: #1a1a1a;
$mainColor-2: #414141;
$mainColor-3: #6c6c6c;

$mainTextColor-1: white;
$mainTextColor-2: #f2ecff;

$CTA-color: #00b98a;

$relegationZoneColor: rgb(255, 225, 198);
$relegationZoneBorderColor: rgb(255, 201, 151);

$eliminationColor: rgb(255, 218, 218);
$eliminationBorderColor: rgb(255, 151, 151);
