$female_mainColor-1: #531987;
$female_mainColor-2: #7c40b0;
$female_mainColor-3: #a666da;

$female_mainTextColor-1: white;
$female_mainTextColor-2: black;

$female_CTA-color: #61baad;

$female_relegationZoneColor: rgb(255, 225, 198);
$female_relegationZoneBorderColor: rgb(255, 201, 151);

$female_eliminationColor: rgb(255, 218, 236);
$female_eliminationBorderColor: rgb(219, 157, 187);
