@import "../commonStyles/commonText.scss";

@import "../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";

.tournamentHomePage {
  padding-bottom: 60px;

  .heroSectionAndTableContainer {
    .heroSection {
      position: sticky;
      top: 60px;

      display: flex;
      align-items: center;

      width: 100%;
      height: 90vh;
      min-height: 490px;
      max-height: calc(90vh - 60px);

      z-index: -1;

      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

      .backgroundGradient {
        position: absolute;
        top: 0;
        z-index: -1;

        background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.2),
          #f0f0f0
        );

        height: 100%;
        width: 100%;
      }
    }

    .logoAndTableContainer {
      padding-bottom: 40px;

      .logoAndNameContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logoAndName {
          display: flex;
          flex-direction: column;
          align-items: center;

          background-color: rgba(0, 0, 0, 0.4);
          border: 1px solid rgba(0, 0, 0, 0.2);

          width: 80%;
          max-width: 500px;
          padding: 10px;
          border-radius: 20px;

          margin-bottom: 20px;

          .tournamentLogoContainer {
            display: flex;
            justify-content: center;

            width: 100%;

            margin-bottom: 10px;

            .tournamentLogo {
              display: flex;
              width: 100%;
              height: 100%;
              max-width: 400px;
              max-height: calc(300px + 2vh);
              object-fit: contain;

              filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
            }
          }

          .tournamentNameContainer {
            text-align: center;
            color: white;
            text-shadow: 0px 0px 8px black;

            padding: 2px 10px;
            border-radius: 10px;

            font-size: calc(20px + 1.2vw);
            font-weight: 600;
          }
        }
      }

      .statsPageNavbarContainer {
        display: flex;
        justify-content: center;

        .statsPageNavbar {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 30px;

          border: 2px solid $whiteTourney_mainColor-2;
          background-color: white;

          height: 60px;
          width: 100%;
          max-width: 640px;
          border-radius: 20px;
          margin: 20px 20px;

          .statsPageNavbarLink {
            display: flex;
            justify-content: center;
            align-items: center;

            color: black;

            text-decoration: none;
            font-size: 20px;
            font-weight: 600;

            height: 93.6%;

            border-bottom: 4px solid transparent;

            &.statsPageNavbarLink__active {
              border-bottom: 4px solid $whiteTourney_mainTextColor-3;
            }
          }
        }
      }

      .playoffsSectionWrapper {
        display: flex;
        justify-content: center;

        .playoffsSectionContainer {
          display: flex;
          align-items: center;
          gap: 16px;

          padding: 0px 16px;

          overflow-x: auto;

          .playoffFazeContainer {
            display: flex;
            flex-direction: column;
            gap: 16px 0px;

            .playoffCard {
              background-color: white;

              max-width: fit-content;
              padding: 8px;
              border-radius: 8px;

              .topSection {
                .playoffNameAndTimeContainer {
                  font-weight: 500;
                }

                .dateContainer {
                  font-size: 14px;
                }
              }

              .teamCardsContainer {
                display: flex;
                flex-direction: column;
                gap: 8px 0px;

                .teamCardContainer {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  width: 238px;

                  .teamCard {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .teamName {
                      min-width: 150px;
                      max-width: 150px;
                    }
                  }

                  .teamScore {
                    font-size: 20px;

                    .penaltiesScore {
                      font-size: 16px;
                    }
                  }
                }
              }

              .matchInfoModal {
                .background {
                  position: fixed;
                  top: 0;
                  left: 0;

                  z-index: 1000;

                  height: 100vh;
                  width: 100vw;

                  background-color: rgba(0, 0, 0, 0.7);
                  backdrop-filter: blur(1px);
                }

                .modal {
                  position: fixed;
                  left: 50%;
                  bottom: 0;
                  transform: translateX(-50%);
                  z-index: 1000;

                  display: flex;
                  flex-direction: column;
                  gap: 30px;

                  height: calc(100vh - (60px * 3.5));
                  width: 85vw;
                  max-width: 400px;
                  padding: 20px 8px;
                  padding-bottom: 40px;
                  border-radius: 30px 30px 0px 0px;

                  background-color: #f0f0f0;

                  overflow-y: auto;

                  @keyframes MoveModalUp {
                    0% {
                      bottom: -100%;
                    }
                  }

                  animation-name: MoveModalUp;

                  animation-duration: 300ms;
                  animation-iteration-count: 1;

                  &::-webkit-scrollbar {
                    width: 0px;
                  }

                  .timeAndDate {
                    text-align: center;
                    font-weight: 500;

                    .stadiumName {
                      line-height: 1;
                      font-size: 18px;
                    }
                  }

                  .teamsContainer {
                    display: flex;

                    .teamCard {
                      .teamName {
                        text-align: center;
                      }
                    }

                    .matchInfoContainer {
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      text-align: center;

                      width: 100%;

                      .timeAndDate {
                        line-height: 1;

                        .time {
                          font-weight: 600;
                          font-size: 18px;
                        }

                        .date {
                          margin-bottom: 2px;
                          color: gray;
                          font-size: 14px;

                          @include Ellpisis(1);
                        }
                      }

                      .scoreContainer {
                        display: flex;
                        align-items: center;
                        font-weight: 600;

                        .teamScore {
                          display: flex;
                          align-items: center;
                          font-size: 26px;
                          font-weight: 700;

                          &:last-child {
                            flex-direction: row-reverse;
                          }

                          .penaltiesScore {
                            font-size: 16px;
                            font-weight: 600;
                          }
                        }

                        .vsText {
                          margin: 0px 5px;
                          font-size: 26px;

                          &.finalText,
                          &.liveText {
                            font-size: 14px;
                            font-weight: 500;
                          }
                        }

                        .liveText {
                          color: rgb(245, 24, 24);
                        }
                      }

                      .stadiumName {
                        color: gray;
                        font-size: 14px;

                        @include Ellpisis(1);
                      }
                    }
                  }

                  .teamPlayersSection {
                    background-color: white;
                    border: 1px solid lightgray;
                    padding: 8px;
                    border-radius: 7px;

                    .teamLogoContainer {
                      display: flex;
                      align-items: center;
                      gap: 5px;

                      font-weight: 600;

                      margin-bottom: 10px;
                    }

                    .playerContainer__tableHeader {
                      color: gray;
                    }

                    .playerContainer {
                      display: flex;
                      align-items: center;
                      gap: 5px;
                      height: 40px;

                      &.playerContainer:not(:last-child) {
                        border-bottom: 1px solid rgb(233, 233, 233);
                      }

                      .playerNumber {
                        width: 30px;
                      }

                      .playerName {
                        text-wrap: nowrap;
                        .playerNickname {
                          font-size: 13px;
                          color: gray;
                        }
                      }

                      .statsSection {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 2px;

                        .iconsContainer {
                          display: flex;
                          flex-wrap: wrap;
                          gap: 2px;
                          line-height: 1;
                          max-height: 100%;

                          .statIcon {
                            line-height: 1;

                            // background-color: red;

                            span,
                            i {
                              font-size: 18px;
                            }

                            .matchCard {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              box-shadow: 0px 0px 2cm rgba(0, 0, 0, 0.25);

                              width: 16px;

                              aspect-ratio: 3/4;
                              border-radius: 3px;
                            }

                            .matchCard__fouls {
                              color: red;
                            }

                            .matchCard__yellow {
                              background-color: rgb(255, 208, 0);
                            }

                            .matchCard__red {
                              background-color: red;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .tableTeamRankingsSection {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        gap: 30px;
      }
    }
  }

  .jornadaSchedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
