$screenSize-small: 600px;
$screenSize-medium: 601px;
$screenSize-large: 1200px;

.thePage {
  padding-top: 60px;

  @media only screen and (max-width: $screenSize-small) {
    padding-left: 0px;
    padding-bottom: 80px;
  }

  @media only screen and (min-width: $screenSize-medium) {
    padding-left: 80px;
  }

  @media only screen and (min-width: $screenSize-large) {
    padding-left: 220px;
  }
}

.mobileFooterContainer {
  display: none;

  position: relative;
  z-index: 99;

  @media only screen and (max-width: $screenSize-small) {
    display: inline;
  }
}

.smallSidebarContainer {
  display: none;

  position: relative;
  z-index: 99;

  @media only screen and (min-width: $screenSize-medium) {
    display: inline;
  }
}

.largeSidebarContainer {
  display: none;

  position: relative;
  z-index: 99;

  @media only screen and (min-width: $screenSize-large) {
    display: inline;
  }
}
