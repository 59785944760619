$cardHeight: 320px;
$cardWidth: 260px;

.playerStatsCard__creatorCard {
  // background-color: rgba(46, 134, 216, 0.1);
}

.playerStatsCard {
  position: relative;

  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.4);
  color: white;
  text-shadow: -1px 1px 2px black;

  min-height: $cardHeight;
  min-width: $cardWidth;
  max-height: $cardHeight;
  max-width: $cardWidth;
  border-radius: 7px;

  margin: 0px 5px;

  overflow: hidden;

  background-image: red;

  .creatorOfWebsiteOverlay {
    position: absolute;
    left: 0px;
    top: 0px;
    // z-index: 2;

    background: repeating-linear-gradient(
      rgba(46, 134, 216, 0.2),
      rgba(46, 134, 216, 0.2) 4px,
      rgba(46, 134, 216, 0.5) 4px,
      rgba(46, 134, 216, 0.5) 6px
    );
    background-size: 100% 9px;
    height: 100%;
    width: 100%;

    animation: pan-overlay 15s infinite linear;
  }

  @keyframes pan-overlay {
    from {
      background-position: 0% 0%;
    }

    to {
      background-position: 0% -100%;
    }
  }

  .playerImage {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    object-fit: cover;

    // background-size: 300%;

    z-index: -1;
  }

  .topInfoContainer {
    position: absolute;
    top: 0;
    left: 0;

    padding: 10px 5px;

    .logoAndNameContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      max-width: 55px;

      .logoLink {
        text-decoration: none;
      }

      .name {
        text-align: center;
        text-shadow: 0px 0px 2px black;
        font-size: 11px;

        width: 70px;
      }
    }

    .yearContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .yearValue {
        font-size: 20px;
      }

      .yearTxt {
        font-size: 14px;
        line-height: 0.7;
      }
    }
  }

  .placementTxt {
    position: absolute;
    top: 0;
    right: 10px;

    display: flex;
    align-items: flex-end;

    font-size: 30px;
    font-weight: 500;

    .hashIcon {
      font-size: 22px;
    }
  }

  .infoContainer {
    position: absolute;
    bottom: 0;

    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.7)
    );

    width: 100%;
    padding-top: 10px;

    .nameAndNicknameContainer {
      line-height: 1.1;
      margin-bottom: 10px;

      .name {
        text-align: center;
        font-size: 20px;
      }

      .nickname {
        text-align: center;
        font-size: 15px;
      }
    }

    .allStatsContainer {
      .statsContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        gap: 5px;
        margin-bottom: 5px;

        .statBox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(2px);

          background-color: rgba(0, 0, 0, 0.25);
          box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.3);

          height: 45px;
          width: 45px;
          padding: 6px;
          border-radius: 3px;

          .stat_number {
            display: flex;
            align-items: center;

            font-size: 22px;
            font-weight: 500;

            height: 22px;
            margin-top: -3px;
            margin-bottom: 2px;
          }

          .stat_title {
            text-align: center;

            font-size: 12px;

            line-height: 1;
          }
        }
      }
    }
  }
}
