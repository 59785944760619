@import "../../../../styles-colorLibraries/MainPagesColors";
@import "../../../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../../../styles-colorLibraries/FemaleTourneyColors.scss";

// Tourney Color Libraries
@import "../../../../styles-colorLibraries/tournaments/green_tourneyColors.scss";
@import "../../../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";
@import "../../../../styles-colorLibraries/tournaments/red_tourneyColors.scss";
@import "../../../../styles-colorLibraries/WhiteTourneyColors.scss";

$largeSidebarHeight: 100%;
$largeSidebarWidth: 220px;
$sidebarBtnIconFontSize: 22px;

@mixin pageLink {
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;

    .material-symbols-outlined {
      font-size: $sidebarBtnIconFontSize + 10px;
    }
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

@mixin dropDownBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;

  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
  }

  .cartIconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;

    .cartTotalItems {
      position: absolute;
      color: $mainColor-1;

      font-size: 12px;
      font-weight: 700;

      margin-left: 3px;
      margin-top: -1px;
    }
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

@mixin button {
  display: flex;
  align-items: center;

  color: white;
  text-decoration: none;

  height: 40px;
  width: $largeSidebarWidth/1.15;

  border-radius: 8px;
  border: none;
  margin-bottom: 1px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 22px;

    font-size: $sidebarBtnIconFontSize;

    margin-left: 10px;
    margin: 8px;
  }

  .linkText {
    margin-left: 25px;
    font-size: 14px;
  }
}

.largeSidebar {
  position: fixed;

  background-color: $mainColor-1;
  color: white;
  box-shadow: 4px 10px 8px rgba(0, 0, 0, 0.25);

  height: $largeSidebarHeight;
  width: $largeSidebarWidth;

  margin-top: 60px;

  .topLinksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin-top: 10px;

    border-bottom: 1px solid rgba(161, 161, 161, 0.521);

    .dropDownBtn {
      @include dropDownBtn();
    }

    .dropDownBtn:hover {
      background-color: $mainColor-2;
    }

    .dropdown-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 10px;

      border-bottom: 1px solid rgba(156, 206, 149, 0.521);
    }

    .activePageLink {
      @include pageLink();
      background-color: $mainColor-2;
    }

    .pageLink {
      @include pageLink();
    }

    .pageLink:hover {
      background-color: $mainColor-2;
    }
  }

  .extraInfoContainer {
    margin-top: 20px;

    .extraInfoBox {
      text-align: center;
      font-size: 12px;

      width: 100%;

      a {
        color: white;
      }
    }
  }
}

.largeSidebar__MainPages {
  .topLinksContainer {
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);

    .activePageLink {
      @include pageLink();
      color: black;
      background-color: red;
    }

    .pageLink {
      @include pageLink();
      color: black;

      &:hover {
        background-color: #04599f44;
      }
    }
  }

  .extraInfoContainer {
    color: black;

    .extraInfoBox {
      a {
        color: black;
      }
    }
  }
}

.largeSidebar__tournament__white {
  background-color: $whiteTourney_mainColor-1;
  color: $whiteTourney_mainTextColor-3;

  .topLinksContainer {
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);

    .activePageLink {
      @include pageLink();
      color: $whiteTourney_mainTextColor-2;
      background-color: $whiteTourney_mainColor-2;
    }

    .pageLink {
      @include pageLink();
      color: $whiteTourney_mainTextColor-3;

      &:hover {
        background-color: #04599f44;
      }
    }
  }

  .extraInfoContainer {
    .extraInfoBox {
      a {
        color: $whiteTourney_mainTextColor-3;
      }
    }
  }
}

.largeSidebar__tournament__red {
  background-color: $red_mainColor-1;
  color: $red_mainTextColor-3;

  .topLinksContainer {
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);

    .activePageLink {
      @include pageLink();
      color: $red_mainTextColor-2;
      background-color: $red_mainColor-2;
    }

    .pageLink {
      @include pageLink();
      color: $red_mainTextColor-3;

      &:hover {
        background-color: $red_mainColor-2-hover;
      }
    }
  }

  .extraInfoContainer {
    .extraInfoBox {
      a {
        color: $red_mainTextColor-3;
      }
    }
  }
}

.largeSidebar__tournament__purple {
  background-color: $purple_mainColor-1;
  color: $purple_mainTextColor-3;

  .topLinksContainer {
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);

    .activePageLink {
      @include pageLink();
      color: $purple_mainTextColor-2;
      background-color: $purple_mainColor-2;
    }

    .pageLink {
      @include pageLink();
      color: $purple_mainTextColor-3;

      &:hover {
        background-color: $purple_mainColor-2-hover;
      }
    }
  }

  .extraInfoContainer {
    .extraInfoBox {
      a {
        color: $purple_mainTextColor-3;
      }
    }
  }
}

.largeSidebar__tournament__green {
  background-color: $green_mainColor-1;
  color: $green_mainTextColor-3;

  .topLinksContainer {
    border-bottom: 1px solid rgba(134, 134, 134, 0.5);

    .activePageLink {
      @include pageLink();
      color: $green_mainTextColor-2;
      background-color: $green_mainColor-2;
    }

    .pageLink {
      @include pageLink();
      color: $green_mainTextColor-3;

      &:hover {
        background-color: $green_mainColor-2-hover;
      }
    }
  }

  .extraInfoContainer {
    .extraInfoBox {
      a {
        color: $green_mainTextColor-3;
      }
    }
  }
}
