.betaStatsNotReady {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  height: calc(100vh - 60px);

  font-size: calc(16px + 3vw);

  .otherText {
    font-size: calc(12px + 2vw);
  }
}
