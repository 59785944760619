@import "../commonStyles/commonText.scss";

// Colors
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";

$teamCardHeight: 220px;
$teamCardMaxWidth: 400px;

.allTeamsPage {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  gap: 8px;

  transition: opacity 0.2s ease;

  padding: 8px;

  .teamCardContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    transition: 200ms ease-in-out;

    .teamCard {
      position: relative;

      background-color: white;
      color: black;
      box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.3);

      width: 100%;
      aspect-ratio: 4/3;

      border-radius: 5px;

      overflow: hidden;

      text-decoration: none;

      &:hover {
        .teamLogo {
          scale: 1.06;
          font-size: 58px;
        }
      }

      .teamLogo {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: rgb(200, 200, 200);

        border-bottom: 1px solid rgb(200, 200, 200);

        height: 100%;
        width: 100%;

        font-size: 50px;
        font-weight: 500;

        transition: 180ms ease-in-out;

        img {
          height: 100%;
          width: 100%;

          object-fit: cover;
          object-position: center center;
        }
      }

      .infoContainer {
        position: absolute;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;

        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0.8),
          rgba(0, 0, 0, 0)
        );

        height: 20%;
        width: 100%;
        padding: 10px 0px;

        img {
          max-height: 45px;
          max-width: 45px;
        }

        .teamName {
          text-align: center;

          color: white;
          text-shadow: -1px 1px 2px rgb(0, 0, 0);
          font-size: 24px;
          font-weight: 500;

          @include Ellpisis(2);
        }
      }
    }

    .teamCard_v2 {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      background-color: black;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      color: white;
      text-decoration: none;

      width: 100%;
      aspect-ratio: 3/4;
      padding: 10px;
      border-radius: 7px;

      overflow: hidden;

      transition: 200ms ease-in-out;

      &:hover {
        .logoContainer {
          width: 83%;
        }

        .backgroundLogoContainer {
          .backgroundImage {
            opacity: 0.6;
          }

          .backgroundLogoContainer {
            width: 135%;
          }
        }
      }

      .logoContainer {
        display: flex;
        align-items: center;

        width: 80%;
        height: 100%;
        z-index: 1;

        transition: 200ms ease-in-out;

        .teamLogo {
          max-width: 100%;
          max-height: 80%;
        }

        .firstLetterContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 100%;
          aspect-ratio: 1;
          border-radius: 50%;

          font-size: calc(50px);
          font-weight: 500;
        }
      }

      .teamName {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.25);
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

        height: 50px;
        width: 100%;
        border-radius: 5px;
        font-weight: 500;
      }

      .backgroundLogoContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .backgroundLogo {
          width: 130%;
        }
      }

      .backgroundLogoContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .backgroundImage {
          position: absolute;
          top: 0;
          z-index: 0;

          height: 100%;
          width: 100%;

          opacity: 0.9;

          transition: 200ms ease-in-out;
        }

        .backgroundLogoContainer {
          position: absolute;
          z-index: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 145%;

          transition: 200ms ease-in-out;

          .backgroundLogo {
            width: 100%;
          }
        }
      }
    }
  }
}

// .allTeamsPage:has(.teamCardContainer:hover) > .teamCardContainer:not(:hover) {
//   scale: 0.95;

//   .teamCardContainer {
//     .teamCard {
//       border-radius: 50px;
//     }
//   }
// }
