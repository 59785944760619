$cardHeight: 300px;
$cardMaxWidth: 500px;

$imageHeight: $cardHeight - 20;

.tournamentCard_v2 {
  background-color: white;
  color: black;
  border: 1px solid lightgray;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  width: 100%;
  border-radius: 5px;

  font-weight: 500;
  text-decoration: none;

  overflow: hidden;

  .tournamentThumbnailContainer {
    width: 100%;
    aspect-ratio: 16/9;

    .tournamentThumbnail {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  .infoSection {
    padding: 5px 8px;

    .tournamentName {
      color: black;
      font-size: 18px;
    }

    .tournamentHost {
      color: gray;

      font-size: 16px;
    }

    .tournamentSex {
      &.tournamentSex__male {
        color: rgb(0, 81, 255);
      }

      &.tournamentSex__female {
        color: rgb(228, 7, 248);
      }
    }
  }
}

.tournamentCard {
  position: relative;

  background-color: rgb(191, 191, 191);

  width: 100%;
  aspect-ratio: 3 / 2;

  overflow: hidden;

  text-decoration: none;

  .finishedOverlay {
    position: absolute;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(20, 21, 22, 0.6);

    width: 100%;
    height: 100%;

    .theText {
      padding: 3px;

      font-size: 25px;
      font-weight: 500;
      transform: rotate(-10deg);
    }

    .isCompletedText {
      border: 3px solid red;
      color: red;
    }

    .isTestText {
      border: 3px solid rgb(255, 140, 0);
      color: rgb(255, 140, 0);
    }
  }

  .logoContainer {
    position: absolute;
    left: 10px;
    top: 10px;

    width: 15%;

    // display: none;

    img {
      width: 100%;
      height: 100%;
      max-height: 110px;
      max-width: 180px;

      border-radius: 3px;
    }
  }

  .imageContainer {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;

      object-fit: cover;
      object-position: center center;

      opacity: 1;
    }
  }

  .tournamentInfoContainer {
    position: absolute;
    bottom: 0;

    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.9),
      60%,
      rgba(0, 0, 255, 0)
    );
    color: white;
    text-shadow: -1px 1px 2px black;

    height: 180px;
    width: 100%;

    padding-left: 10px;
    padding-bottom: 10px;

    .info {
      .tournamentName {
        font-size: 18px;
        font-weight: 400;

        width: 100%;

        container-name: containersize;
      }

      .tournamentSex {
        width: 100%;
        color: rgb(0, 255, 51);
      }

      .tournamentSex__male {
        color: rgb(0, 170, 255);
      }

      .tournamentSex__female {
        color: rgb(221, 0, 255);
      }

      .tournamentHost {
        width: 100%;
      }

      .locationContainer {
        width: 100%;
        color: rgb(198, 198, 198);
      }
    }
  }
}

.tournamentCompleted {
  .finishedOverlay {
    position: absolute;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(20, 21, 22, 0.6);
    color: red;

    width: 100%;
    height: 100%;

    .isCompletedText {
      padding: 3px;
      border: 3px solid red;
      font-size: 25px;
      font-weight: 500;
      transform: rotate(-10deg);
    }
  }
}

.tournamentNotFinished {
  .finishedOverlay {
    display: none;
  }
}
