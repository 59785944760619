$cardMaxWidth: 380px;

.shopProductCard {
  display: flex;

  width: 100%;

  color: black;
  text-decoration: none;

  .imageContainer {
    height: 200px;
    aspect-ratio: 1;
    margin-right: 8px;

    .productImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 7px;
    }
  }

  .productInfoContainer {
    .productBrand {
      color: gray;
      font-size: 14px;
    }

    .productPrice {
      font-size: 24px;
      font-weight: 600;
      margin: 8px 0px;
    }

    .soldOutText {
      color: rgb(231, 19, 19);
    }
  }
}
