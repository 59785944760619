@import "../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";

.weekScheduleContainer {
  display: flex;
  justify-content: center;

  .weekSchedule {
    margin-top: 10px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    max-width: 600px;
    // margin: 3px;

    .title {
      background-color: $mainColor-2;
      color: $mainTextColor-1;

      width: 100%;
      padding: 10px 0px;

      @media only screen and (min-width: 685px) {
        border-radius: 3px;
      }

      .titleText {
        margin-left: 10px;
        font-size: 18px;
        font-weight: 500;
      }
    }

    .dayMatches {
      margin: 5px;
      width: 100%;

      .date {
        display: flex;
        align-items: center;

        background-color: $mainColor-3;
        color: $mainTextColor-2;
        padding: 5px 7px;

        border-radius: 3px;

        .text {
          margin-bottom: 3px;
          font-size: 17px;
          font-weight: 500;
        }
      }

      // .match {
      //   color: rgb(0, 0, 0);
      //   padding: 5px 0px;
      //   text-decoration: none;

      //   .finalsMatch {
      //     text-align: center;
      //     font-weight: 500;
      //     font-size: 18px;

      //     width: 100%;
      //   }

      //   .time {
      //     text-align: center;
      //     font-size: 18px;
      //   }

      //   .teams {
      //     display: flex;
      //     justify-content: center;

      //     .teamTxt {
      //       display: flex;
      //       align-items: center;

      //       font-weight: 500;
      //       font-size: 18px;
      //       width: 50%;

      //       .goals {
      //         display: flex;
      //         align-items: center;

      //         font-size: 24px;

      //         margin: 0px 3px;

      //         .penaltiesScore {
      //           font-size: 18px;
      //         }
      //       }
      //     }

      //     .team1 {
      //       justify-content: flex-end;
      //     }

      //     .teamNameTxt {
      //       text-align: center;
      //       padding: 0px 2px;
      //     }

      //     .matchWinner {
      //       border-bottom: 2px solid red;
      //     }

      //     .vsText {
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       text-align: center;
      //       min-width: 30px;
      //       max-width: 30px;
      //       margin: 0px 5px;

      //       // background-color: red;
      //     }
      //   }
      // }
    }
  }

  .weekSchedule__male {
    .title {
      background-color: $male_mainColor-2;
      color: $male_mainTextColor-1;
    }

    .dayMatches {
      .date {
        background-color: $male_mainColor-3;
        color: $male_mainTextColor-2;

        .teams {
          display: flex;
          justify-content: center;

          .matchWinner {
            border-bottom: 2px solid red;
          }
        }
      }
    }

    .matchInfoModal {
      .teamTableContainer {
        .title {
          background-color: $male_mainColor-1;
        }

        .tableContainer {
          table {
            border: 1px solid $male_mainColor-2;
            background-color: white;

            thead {
              background-color: $male_mainColor-2;
              border-top: 1px solid $male_mainColor-3;
              color: white;

              tr {
                th {
                  border: 1px solid $male_mainColor-1;
                }
              }
            }
          }
        }
      }
    }
  }

  .weekSchedule__female {
    .title {
      background-color: $female_mainColor-2;
      color: $female_mainTextColor-1;
    }

    .dayMatches {
      .date {
        background-color: $female_mainColor-3;
        color: $female_mainTextColor-2;

        .teams {
          display: flex;
          justify-content: center;

          .matchWinner {
            border-bottom: 2px solid red;
          }
        }
      }
    }

    .matchInfoModal {
      .teamTableContainer {
        .title {
          background-color: $female_mainColor-1;
          color: white;
        }

        .tableContainer {
          table {
            border: 1px solid $female_mainColor-2;
            background-color: white;

            thead {
              background-color: $female_mainColor-2;
              border-top: 1px solid $female_mainColor-2;
              color: white;

              tr {
                th {
                  border: 1px solid $female_mainColor-1;
                }
              }
            }
          }
        }
      }
    }
  }

  .weekSchedule__white {
    .title {
      background-color: $whiteTourney_mainColor-2;
      color: $whiteTourney_mainTextColor-2;
    }

    .dayMatches {
      .date {
        background-color: $whiteTourney_mainColor-3;
        color: $whiteTourney_mainTextColor-1;
      }

      .match {
        .teams {
          display: flex;
          justify-content: center;

          .matchWinner {
            border-bottom: 2px solid $whiteTourney_CTA-color;
          }
        }
      }
    }

    .matchInfoModal {
      .teamTableContainer {
        .title {
          background-color: $whiteTourney_mainTextColor-3;
        }

        .tableContainer {
          table {
            border: 1px solid $whiteTourney_mainColor-2;
            background-color: white;

            thead {
              background-color: $whiteTourney_mainColor-2;
              border-top: 1px solid $whiteTourney_mainColor-3;
              color: white;

              tr {
                th {
                  border: 1px solid $whiteTourney_mainTextColor-3;
                }
              }
            }
          }
        }
      }
    }
  }
}
