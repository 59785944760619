.bracket {
  display: flex;

  overflow: hidden;

  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  padding: 0px 10px;

  .quarterFinals {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    width: 100%;

    padding: 10px;

    .stageTxt {
      text-align: center;
      font-size: 18px;
      font-weight: 500;

      width: 100%;
    }

    .match {
      display: flex;

      align-items: center;
      flex-direction: column;
      flex-wrap: wrap;

      margin-bottom: 0px;
      width: 100%;

      .matchName {
        text-align: start;
        font-weight: 500;

        width: 100%;
        max-width: 200px;

        margin-bottom: 2px;
      }

      .team {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        gap: 10px;

        background-color: #d5d5d5;

        height: 40px;
        width: 100%;
        min-width: 220px;
        max-width: 220px;
        border-radius: 5px;

        font-weight: 500;

        overflow: hidden;

        margin-bottom: 6px;

        .placement {
          margin-left: 5px;
        }

        .name {
          width: 100%;
          margin-left: 10px;
        }

        .scoreBox {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: #aaaaaa;

          height: 100%;
          min-width: 40px;

          font-weight: 500;

          .scoreTxt {
            font-size: 18px;
          }

          .penaltyTxt {
            font-size: 14px;
          }
        }
      }

      .winner {
        .scoreBox {
          background-color: #00c896;
        }
      }
    }
  }
}

.bracket__male {
  .quarterFinals {
    .match {
      .winner {
        .scoreBox {
          background-color: #0094ff;
        }
      }
    }
  }
}

.bracket__female {
  .quarterFinals {
    .match {
      .winner {
        .scoreBox {
          background-color: rgb(141, 122, 239);
        }
      }
    }
  }
}
