@import "../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/red_tourneyColors.scss";

.schedulePage {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.jornadaContainer {
  padding-top: 10px;
  margin: 5px;
  width: 100%;
  max-width: 640px;

  .title {
    background-color: $mainColor-2;
    color: $mainTextColor-1;

    width: 100%;
    padding: 10px 0px;
    border-radius: 5px;

    .titleTxt {
      margin-left: 10px;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .teamRest {
    color: red;
    font-weight: 500;
    text-align: center;
  }
}

.jornadaContainer__male {
  .title {
    background-color: $male_mainColor-2;
    color: $male_mainTextColor-1;
  }
}

.jornadaContainer__female {
  .title {
    background-color: $female_mainColor-2;
    color: $female_mainTextColor-1;
  }
}

.jornadaContainer__white {
  .title {
    background-color: $whiteTourney_mainColor-2;
    color: $whiteTourney_mainTextColor-2;
  }

  .matchContainer {
    .teams {
      .matchWinner {
        border-bottom: 2px solid $whiteTourney_CTA-color;
      }
    }
  }

  .teamRest {
    color: $whiteTourney_CTA-color;
  }
}

.jornadaContainer__purple {
  .title {
    background-color: $purple_mainColor-2;
    color: $purple_mainTextColor-2;
  }

  .matchContainer {
    .teams {
      .matchWinner {
        border-bottom: 2px solid $purple_CTA-color;
      }
    }
  }

  .teamRest {
    color: $purple_CTA-color;
  }
}

.jornadaContainer__red {
  .title {
    background-color: $red_mainColor-2;
    color: $red_mainTextColor-2;
  }

  .matchContainer {
    .teams {
      .matchWinner {
        border-bottom: 2px solid $red_CTA-color;
      }
    }
  }

  .teamRest {
    color: $red_CTA-color;
  }
}
