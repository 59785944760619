.shopHomePage {
  gap: 15px;

  margin-top: 10px;
  margin: 10px;

  .productsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 20px;
    place-items: center;
  }
}
