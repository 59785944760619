.promoteMySelf {
  text-align: center;
  margin: 30px 0px 10px 0px;

  .socialMediaSections {
    .socialLink {
      text-decoration: none;

      i {
        margin: 5px 10px;
        font-size: 35px;

        &.fa-youtube {
          color: #c4302b;
        }

        &.fa-whatsapp {
          color: #25d366;
        }
      }

      .coloredInstaIcon {
        font-size: 35px;
        background-image: linear-gradient(
          45deg,
          #feda75,
          #fa7e1e,
          #d62976,
          #962fbf,
          #4f5bd5
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
