$whiteTourney_mainColor-1: #ffffff;
$whiteTourney_mainColor-2: #0089bf;
$whiteTourney_mainColor-3: #5ec9eb;

$whiteTourney_mainColor-2-hover: #04599f66;

$whiteTourney_mainTextColor-1: rgb(0, 0, 0);
$whiteTourney_mainTextColor-2: rgb(255, 255, 255);
$whiteTourney_mainTextColor-3: #04589f;

$whiteTourney_CTA-color: #fd8b09;

$whiteTourney_relegationZoneColor: rgb(255, 225, 198);
$whiteTourney_relegationZoneBorderColor: rgb(255, 201, 151);

$whiteTourney_eliminationColor: rgb(255, 218, 218);
$whiteTourney_eliminationBorderColor: rgb(255, 151, 151);
