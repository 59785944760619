@import "../../../styles-colorLibraries/globalColors";
@import "../../../styles-commonStyles/skeleton.scss";

// __________ Colors __________
@import "../../../styles-colorLibraries/WhiteTourneyColors.scss";
@import "../../../styles-colorLibraries/tournaments/red_tourneyColors.scss";
@import "../../../styles-colorLibraries/tournaments/green_tourneyColors.scss";
@import "../../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";

.teamRankingsTable {
  display: flex;
  justify-content: center;

  .tableContainer {
    display: flex;
    justify-content: center;

    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .newTable {
      border-collapse: collapse;
      border-radius: 10px;

      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
      font-weight: 500;

      overflow: hidden;

      thead {
        font-size: 14px;
        background-color: $whiteTourney_mainColor-2;
        color: white;

        th {
          font-weight: 500;
          padding: 4px 0px;

          &.activeCategory {
            background-color: $whiteTourney_mainColor-2-hover;
          }
        }

        .teamTableHeader {
          text-align: start;
          padding-left: 20px;
          font-size: 16px;

          &::before {
            content: "#";
            margin-right: 15px;
          }
        }
      }

      .statsSection {
        .tableTeamRow {
          position: relative;
          border-bottom: 1px solid lightgray;

          &:nth-child(even) {
            background-color: rgb(245, 245, 245);
          }

          @mixin TableBox($widthAmount) {
            min-width: $widthAmount;
            max-width: $widthAmount;
          }

          .tableBox-team {
            display: flex;
            align-items: center;

            height: 50px;
            padding-right: 20px;

            @include TableBox(200px);

            .teamLink {
              display: flex;
              align-items: center;
              color: black;
              text-decoration: none;

              .teamPlacement {
                margin-right: 10px;
                margin-left: 20px;
              }

              .teamName {
                margin-left: 10px;
              }
            }

            .eliminationZone {
              height: 100%;
              width: 5px;

              margin-right: -5px;

              background-color: #00b2ff;
            }
          }

          .tablebox-numbers {
            @include TableBox(60px);
            text-align: center;
            padding: 5px;
          }
        }
      }

      .statsSection__skeleton {
        .tableTeamRow {
          .tableBox-team {
            .teamLink {
              .teamLogoIcon__skeleton {
                background-color: $skeletonColor;

                height: 35px;
                width: 35px;

                border-radius: 1000px;
              }

              .teamName {
                @include TextSkeleton($width: 120px);
              }
            }
          }
          .tablebox-numbers {
            .skeletonTextPlaceholder {
              @include TextSkeleton($width: 26px);
              margin: auto;
            }

            &.tableBox-tiedMatches {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;

              .skeletonTextPlaceholder {
                transform: translateY(2px);
              }
            }
          }
        }
      }
    }
  }

  .tableContainer {
    display: flex;
    justify-content: center;

    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    padding: 0px 16px;
  }
}

// ===============================
// ===============================
// ===============================
// ===============================

.groupTable {
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .tableContainer {
    display: flex;
    justify-content: center;

    display: block;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    .tableAndTitle {
      position: relative;

      width: fit-content;
      padding-top: 20px;
      border-radius: 20px;

      background-color: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);

      margin: 5px 20px;

      overflow: hidden;

      .groupTitle {
        position: sticky;
        left: 20px;

        width: fit-content;

        font-size: 24px;
        font-weight: 600;

        margin-left: 15px;
        margin-bottom: 20px;
      }

      .newTable {
        border-collapse: collapse;
        font-weight: 500;
        overflow: hidden;

        thead {
          font-size: 13px;
          border-bottom: 1px solid lightgray;
          td {
            text-align: center;
            font-weight: 500;
            padding: 4px 0px;

            &.activeCategory {
              background-color: lightgray;
            }
          }

          .teamTableHeader {
            text-align: start;
            padding-left: 20px;
            font-size: 16px;

            &::before {
              content: "#";
              margin-right: 15px;
            }
          }
        }

        .statsSection {
          .tableTeamRow {
            position: relative;

            &:nth-child(even) {
              background-color: rgb(245, 245, 245);
            }

            &:not(:last-child) {
              border-bottom: 1px solid lightgray;
            }

            @mixin TableBox($widthAmount) {
              min-width: $widthAmount;
              max-width: $widthAmount;
            }

            .tableBox-team {
              display: flex;
              align-items: center;

              height: 50px;
              padding-right: 20px;

              @include TableBox(200px);

              .teamLink {
                display: flex;
                align-items: center;
                color: black;
                text-decoration: none;

                .teamPlacement {
                  margin-right: 10px;
                  margin-left: 20px;
                }

                .teamName {
                  font-family: "Oswald", sans-serif;
                  font-size: 18px;
                  font-weight: 400;
                  margin-left: 10px;
                }
              }

              .eliminationZone {
                height: 100%;
                width: 5px;

                margin-right: -5px;

                background-color: #00b2ff;
              }
            }

            .tablebox-numbers {
              @include TableBox(60px);
              text-align: center;
              padding: 5px;
            }
          }
        }
      }
    }
  }
}

// ===============================
// ===============================
// ===============================
// ===============================

.teamRankingsTable__red {
  .tableContainer {
    .newTable {
      thead {
        background-color: $red_mainColor-2;
        color: white;

        th {
          &.activeCategory {
            background-color: $red_mainColor-3;
          }
        }
      }
    }
  }
}

.teamRankingsTable__green {
  .tableContainer {
    .newTable {
      thead {
        background-color: $green_mainColor-2;
        color: white;

        th {
          &.activeCategory {
            background-color: $green_mainColor-3;
          }
        }
      }
    }
  }
}

.teamRankingsTable__purple {
  .tableContainer {
    .newTable {
      thead {
        background-color: $purple_mainColor-2;
        color: white;

        th {
          &.activeCategory {
            background-color: $purple_mainColor-3;
          }
        }
      }
    }
  }
}
