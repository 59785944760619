@import "../../commonStyles/commonText.scss";

// Colors
@import "../../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../../styles-colorLibraries/WhiteTourneyColors.scss";

$teamRowHeight: 35px;

* {
  // outline: 1px solid rgba(255, 0, 0, 0.2);
}

.match_v2 {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;

  .teamContainer {
    .teamNameContainer {
      text-align: center;

      font-size: 13px;
      font-weight: 600;

      width: 80px;
      margin-top: 3px;

      @include Ellpisis(2);
    }
  }

  .matchInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 100%;

    .timeAndDate {
      line-height: 1;

      .time {
        font-weight: 600;
        font-size: 18px;
      }

      .date {
        margin-bottom: 2px;
        color: gray;
        font-size: 14px;

        @include Ellpisis(1);
      }
    }

    .scoreContainer {
      display: flex;
      align-items: center;
      font-weight: 600;

      .scoreNumber {
        display: flex;
        align-items: center;
        font-size: 26px;
        font-weight: 700;

        &:last-child {
          flex-direction: row-reverse;
        }

        .penaltiesScore {
          font-size: 16px;
          font-weight: 600;
        }
      }

      .vsText {
        margin: 0px 5px;
        font-size: 26px;

        &.finalText,
        &.liveText {
          font-size: 14px;
          font-weight: 500;
        }
      }

      .liveText {
        color: rgb(245, 24, 24);
      }
    }

    .stadiumName {
      color: gray;
      font-size: 14px;

      @include Ellpisis(1);
    }
  }

  .matchInfoModal {
    .background {
      position: fixed;
      top: 0;
      left: 0;

      z-index: 1000;

      height: 100vh;
      width: 100vw;

      background-color: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(1px);
    }

    .modal {
      position: fixed;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      z-index: 1000;

      display: flex;
      flex-direction: column;
      gap: 30px;

      height: calc(100vh - (60px * 3.5));
      width: 85vw;
      max-width: 400px;
      padding: 20px 8px;
      padding-bottom: 40px;
      border-radius: 30px 30px 0px 0px;

      background-color: #f0f0f0;

      overflow-y: auto;

      @keyframes MoveModalUp {
        0% {
          bottom: -100%;
        }
      }

      animation-name: MoveModalUp;

      animation-duration: 300ms;
      animation-iteration-count: 1;

      &::-webkit-scrollbar {
        width: 0px;
      }

      .timeAndDate {
        text-align: center;
        font-weight: 500;

        .stadiumName {
          line-height: 1;
          font-size: 18px;
        }
      }

      .teamsContainer {
        display: flex;
      }

      .teamPlayersSection {
        background-color: white;
        border: 1px solid lightgray;
        padding: 8px;
        border-radius: 7px;

        .teamLogoContainer {
          display: flex;
          align-items: center;
          gap: 5px;

          font-weight: 600;

          margin-bottom: 10px;
        }

        .playerContainer__tableHeader {
          color: gray;
        }

        .playerContainer {
          display: flex;
          align-items: center;
          gap: 5px;
          height: 40px;

          &.playerContainer:not(:last-child) {
            border-bottom: 1px solid rgb(233, 233, 233);
          }

          .playerNumber {
            width: 30px;
          }

          .playerName {
            text-wrap: nowrap;
            .playerNickname {
              font-size: 13px;
              color: gray;
            }
          }

          .statsSection {
            display: flex;
            flex-wrap: wrap;
            gap: 2px;

            .iconsContainer {
              display: flex;
              flex-wrap: wrap;
              gap: 2px;
              line-height: 1;
              max-height: 100%;

              .statIcon {
                line-height: 1;

                // background-color: red;

                span,
                i {
                  font-size: 18px;
                }

                .matchCard {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  box-shadow: 0px 0px 2cm rgba(0, 0, 0, 0.25);

                  width: 16px;

                  aspect-ratio: 3/4;
                  border-radius: 3px;
                }

                .matchCard__fouls {
                  color: red;
                }

                .matchCard__yellow {
                  background-color: rgb(255, 208, 0);
                }

                .matchCard__red {
                  background-color: red;
                }
              }
            }
          }
        }
      }
    }
  }
}

.match_v2:not(:last-child) {
  border-bottom: 1px solid lightgray;
}
