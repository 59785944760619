$male_mainColor-1: #194d8e;
$male_mainColor-2: #0089bf;
$male_mainColor-3: #5ec9eb;

$male_mainTextColor-1: white;
$male_mainTextColor-2: black;

$male_CTA-color: #74aeaa;

$male_relegationZoneColor: rgb(255, 225, 198);
$male_relegationZoneBorderColor: rgb(255, 201, 151);

$male_eliminationColor: rgb(255, 218, 218);
$male_eliminationBorderColor: rgb(255, 151, 151);
