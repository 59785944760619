.homePage {
  .tournamentCardsSection {
    display: flex;
    justify-content: center;
    margin: 10px;

    .tournamentsContainerWrapper {
      display: flex;
      justify-content: center;

      width: 100%;

      transition: ease all 0.5s;

      .tournamentsContainer {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
        grid-gap: 10px;
      }
    }
  }

  .promoteMySelf__mainPages {
    text-align: center;
    margin-top: 40px;
    color: rgb(165, 165, 165);

    .socialMediaSections {
      .socialLink {
        text-decoration: none;

        i {
          margin: 5px 10px;
          font-size: 35px;
          color: rgb(190, 190, 190);

          &.fa-youtube {
          }
        }
      }
    }
  }
}
