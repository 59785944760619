.shopProductPage {
  margin-bottom: 100px;

  .topInfoSection {
    padding: 0px 5px;
    margin-bottom: 10px;

    .backLink {
      color: rgb(13, 108, 218);
      text-decoration: none;
    }

    .productName {
      font-size: 18px;
    }

    .productBrand {
      color: rgb(84, 86, 88);
    }
  }

  .productImagesContainer {
    .productImageContainer {
      width: 100%;
      aspect-ratio: 1/0.9;

      background-color: rgb(233, 236, 238);

      img {
        display: flex;

        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center center;
      }
    }

    .extraImagesContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-top: 10px;
      gap: 10px;

      .imageButton {
        width: 60px;
        aspect-ratio: 1;

        background-color: rgb(233, 236, 238);

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;

          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);

          object-fit: cover;
          object-position: center center;
        }
      }
    }
  }

  .infoSection {
    .topInfoSection {
      display: none;
    }

    @media only screen and (min-width: 800px) {
      .topInfoSection {
        display: block;
      }
    }

    .productInfoSection {
      margin-top: 10px;

      .priceTxt {
        font-size: 22px;
      }

      .isUnavailable {
        color: red;
        font-size: 20px;
      }
    }

    .orderBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;

      margin-top: 20px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        color: white;
        background-image: linear-gradient(to bottom, #25d366, #079c3e);
        text-decoration: none;

        border-radius: 5px;

        width: 90%;
        max-width: 400px;
        padding: 10px 0px;

        font-size: 18px;
        font-weight: 400;

        i {
          font-size: 26px;
        }
      }
    }
  }

  .extraDetailsContainer {
    margin: 0px 10px;
    margin-top: 20px;
  }

  @media only screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 15px;
    margin: 10px;

    @media only screen and (min-width: 1000px) {
      grid-template-columns: 45% 55%;
      gap: 25px;
    }

    .topInfoSection {
      display: none;
    }

    .productImagesContainer {
      .productImageContainer {
        width: 100%;

        img {
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
        }
      }
    }

    .infoSection {
      width: 100%;

      .orderBtnContainer {
        justify-content: flex-start;
      }
    }
  }
}
