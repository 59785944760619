$purple_mainColor-1: #ffffff;
$purple_mainColor-2: #9d24b1;
$purple_mainColor-3: #bf49d2;

$purple_mainColor-2-hover: #7b2ae644;

$purple_mainTextColor-1: rgb(0, 0, 0);
$purple_mainTextColor-2: rgb(255, 255, 255);
$purple_mainTextColor-3: #bf49d2;

$purple_CTA-color: #fd8b09;

$purple_relegationZoneColor: rgb(255, 225, 198);
$purple_relegationZoneBorderColor: rgb(255, 201, 151);

$purple_eliminationColor: rgb(255, 218, 218);
$purple_eliminationBorderColor: rgb(255, 151, 151);
