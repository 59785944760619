$red_mainColor-1: #ffffff;
$red_mainColor-2: #c72a2a;
$red_mainColor-3: #eb4d43;

$red_mainColor-2-hover: #e0656544;

$red_mainTextColor-1: rgb(0, 0, 0);
$red_mainTextColor-2: rgb(255, 255, 255);
$red_mainTextColor-3: #d8344a;

$red_CTA-color: #fd8b09;

$red_relegationZoneColor: rgb(255, 225, 198);
$red_relegationZoneBorderColor: rgb(255, 201, 151);

$red_eliminationColor: rgb(255, 218, 218);
$red_eliminationBorderColor: rgb(255, 151, 151);
