@import "../components/TournamentNavbar.scss";
@import "../components/NavigationComponents/Tournament/LargeSidebar.scss";
@import "../components/NavigationComponents/Tournament/SmallSidebar.scss";

// Colors
@import "../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";

$splashScreenAnimTime: 1.5s;

.teamInfoPage {
  .teamHeroSection {
    position: relative;

    .teamImageContainerWrapper {
      padding: 20px;

      display: flex;
      justify-content: center;

      .teamImageContainer {
        position: relative;
        z-index: 1;

        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

        width: 100%;
        max-width: 600px;
        max-height: 400px;
        border-radius: clamp(16px, 10vw, 20px);

        overflow: hidden;

        .teamImage {
          width: inherit;
          max-height: inherit;

          object-fit: cover;
          display: flex;
        }

        .backgroundGradient {
          position: absolute;
          top: 0;
          left: 0;
          background-image: linear-gradient(
            to bottom,
            transparent,
            transparent,
            rgba(0, 0, 0, 0.9)
          );
          height: 100%;
          width: 100%;
        }

        .teamNameAndLogo {
          position: absolute;
          bottom: 20px;
          left: 50%;
          transform: translate(-50%, 0%);

          display: flex;
          flex-direction: column;
          align-items: center;

          width: 90%;

          .teamLogo {
            max-width: clamp(70px, 20vw, 120px);
            max-width: clamp(70px, 20vw, 120px);
            filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
          }

          .teamName {
            text-align: center;
            color: white;
            font-size: clamp(22px, 5vw, 40px);
            font-weight: 600;
          }
        }
      }
    }

    .roundedThingInBackground {
      position: absolute;
      bottom: 0;
      z-index: 0;

      background-color: #f0f0f0;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);

      height: calc(60px + 5%);
      width: 100%;
      border-radius: 100% 100% 0px 0px;
    }
  }

  .topSectionWrapper {
    display: flex;
    justify-content: center;

    .topSection {
      position: relative;
      display: flex;

      width: 100%;
      height: auto;
      max-height: calc(100vh - $navbarHeight - 14vh);

      background-color: #f0f0f0;

      .backgroundImg {
        height: 100%;
        width: 100%;

        object-fit: cover;
        object-position: center;
      }

      .backgroundGradient {
        position: absolute;
        bottom: 0;

        height: 100px;
        width: 100%;

        background-image: linear-gradient(to bottom, #00000000, #000000da);
      }

      .teamNameContainer {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 10px 0px;

        img {
          max-height: 80px;
          max-width: 80px;

          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.5));
        }

        .teamName {
          color: white;
          text-shadow: -1px 1px 4px black;

          text-align: center;
          font-size: 26px;
          font-weight: 500;
        }
      }
    }
  }

  .teamNameContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 10px 0px;

    img {
      max-height: 80px;
      max-width: 80px;
    }

    .teamName {
      font-size: 26px;
      font-weight: 500;
    }
  }

  .playerStatsSection {
    display: flex;
    justify-content: center;

    .playersContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 100%;
      max-width: 400px;

      padding: 0px 8px;

      .playerCard {
        width: 100%;
        padding: 10px 0px;

        &:not(:last-child) {
          border-bottom: 1px solid lightgray;
        }

        .topSection {
          position: relative;
          display: flex;

          .imgContainer {
            position: relative;

            width: 100px;
            aspect-ratio: 1;

            margin-right: 8px;
            border-radius: 10px;
            overflow: hidden;

            .playerImg {
              display: flex;

              height: 100%;
              width: 100%;

              object-fit: cover;
            }
          }

          .imgContainer__isMartinArreola {
            .playerImg {
              scale: 2;
              animation: pan-image 10s linear infinite;
            }

            .creatorOfWebsiteOverlay {
              position: absolute;
              left: 0px;
              top: 0px;
              border-radius: 10px;

              background: repeating-linear-gradient(
                rgba(46, 134, 216, 0.5),
                rgba(46, 134, 216, 0.5) 3px,
                rgba(46, 134, 216, 0.25) 3px,
                rgba(46, 134, 216, 0.25) 9px
              );

              background-size: 100% 9px;
              height: 100%;
              width: 100%;

              animation: pan-overlay 7s infinite linear;
            }

            @keyframes pan-overlay {
              from {
                background-position: 0% 0%;
              }

              to {
                background-position: 0% -100%;
              }
            }

            @keyframes pan-image {
              0% {
                transform: translate(25%, -25%);
              }

              25% {
                transform: translate(0%, 15%);
              }

              25.0001% {
                transform: translate(-20%, 25%);
              }

              50% {
                transform: translate(0%, 15%);
              }

              50.0001% {
                transform: translate(0%, -25%);
              }

              75% {
                transform: translate(0%, 15%);
              }

              75.0001% {
                transform: translate(20%, 25%);
              }

              100% {
                transform: translate(0%, 15%);
              }
            }
          }

          .playerInfo {
            .playerNickname {
              color: gray;
              font-size: 14px;
            }

            .teamLogoContainer {
              display: flex;
              align-items: center;
              gap: 5px;

              font-size: 18px;
              font-weight: 500;
            }
          }

          .playerNumber {
            position: absolute;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            height: 60px;
            aspect-ratio: 1;
            border-radius: 10px;

            font-size: 22px;
          }
        }

        .bottomSection {
          display: flex;
          justify-content: space-between;
          gap: 8px;

          margin-top: 10px;

          .statBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;

            background-color: white;
            border: 1px solid lightgray;

            width: 100%;
            max-width: 60px;
            aspect-ratio: 1;
            padding: 5px;
            border-radius: 17px;

            font-size: clamp(24px, 7vw, 30px);
            font-weight: 700;
            line-height: 1;

            .statName {
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }

    .playerStatsTableContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      display: block;

      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      max-width: 620px;

      .tableContainer {
        .playerStatsTable {
          border-collapse: collapse;
          font-weight: 500;

          thead {
            border-bottom: 1px solid gray;
            font-size: 18px;

            th {
              padding: 8px 0px;
            }

            .teamColumn {
              text-align: start;
              width: 100%;
              min-width: 250px;

              padding-left: 25px;
            }

            .pointsColumn {
              padding: 0px 5px;
              max-width: 100px;
            }
          }

          .statsSection {
            &:before {
              content: "@";
              display: block;
              line-height: 10px;
              text-indent: -99999px;
            }

            .playerInfoRow {
              .playerInfoContainer {
                $containerHeight: 85px;

                display: flex;
                align-items: center;

                gap: 10px;

                height: $containerHeight;
                padding: 5px;

                .playerPlacement {
                  min-width: 20px;
                  padding-left: 5px;
                  text-align: center;
                  overflow: hidden;
                }

                .imgContainer {
                  position: relative;
                  display: flex;
                  align-items: center;

                  .playerImg {
                    height: $containerHeight;
                    width: $containerHeight;
                    border-radius: 5px;
                    border: 1px solid lightgray;

                    object-fit: cover;

                    pointer-events: none;
                  }

                  .creatorOfWebsiteOverlay {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                    // z-index: 2;

                    background: repeating-linear-gradient(
                      rgba(46, 134, 216, 0.2),
                      rgba(46, 134, 216, 0.2) 4px,
                      rgba(46, 134, 216, 0.5) 4px,
                      rgba(46, 134, 216, 0.5) 6px
                    );
                    background-size: 100% 9px;
                    height: 100%;
                    width: 100%;

                    animation: pan-overlay 10s infinite linear;
                  }

                  @keyframes pan-overlay {
                    from {
                      background-position: 0% 0%;
                    }

                    to {
                      background-position: 0% -100%;
                    }
                  }
                }

                .playerInfo {
                  height: 100%;

                  font-size: 15px;

                  .playerName {
                    font-size: 16px;

                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                  }

                  .playerTeamContainer {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    color: rgb(107, 107, 107);

                    .teamName {
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                    }
                  }
                }
              }

              .playerPoints {
                text-align: center;
                font-size: 20px;
              }
            }
          }
        }
      }

      .showMoreBtn {
        color: #0089bf;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }

  .noInfoTextContainer {
    .noInfoText {
      text-align: center;
      color: red;
      font-size: larger;
      font-weight: 500;
    }
  }

  .moreStatsContainer {
    display: flex;
    justify-content: center;

    .moreStats {
      $borderRadius: 5px;

      display: flex;
      flex-direction: column;
      align-items: center;

      box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2);
      width: 90%;
      max-width: 400px;
      padding: 0px 8px;
      border-radius: $borderRadius;

      margin-top: 20px;

      .sectionTitle {
        background-color: rgb(255, 255, 255);
        border: 1px solid lightgrey;
        border-bottom: 3px solid $CTA-color;

        width: 100%;

        padding: 8px 8px;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;

        font-weight: 500;
        font-size: 22px;
      }

      .statContainer {
        background-color: rgb(255, 255, 255);
        border: 1px solid lightgrey;
        border-top-color: transparent;

        width: 100%;
        padding: 5px 8px;

        font-size: 18px;
      }

      .statContainer:nth-last-child(1) {
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
      }
    }
  }
}

.teamSplashScreen {
  position: fixed;
  top: $navbarHeight;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  z-index: 98;

  width: 100%;
  height: 100%;

  @media only screen and (max-width: 600px) {
    // height: ;
  }

  @media only screen and (min-width: 601px) {
    width: calc(100% - $smallSidebarWidth);
  }

  @media only screen and (min-width: 1200px) {
    width: calc(100% - $largeSidebarWidth);
  }

  color: white;

  @keyframes SplashFadeOut {
    0% {
      opacity: 1;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  animation-name: SplashFadeOut;

  animation-duration: $splashScreenAnimTime;
  animation-iteration-count: 1;

  .teamLogoAndName {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 70%;
    max-width: 300px;
    font-size: clamp(30px, 6vw, 50px);

    margin-bottom: $navbarHeight;

    @keyframes SplashImgGrow {
      0% {
        opacity: 0;
        width: 15%;
        font-size: clamp(10px, 4vw, 40px);
      }

      40% {
        opacity: 1;
        width: 70%;
        font-size: clamp(30px, 6vw, 50px);
      }

      70% {
        opacity: 1;
        width: 70%;
        font-size: clamp(30px, 6vw, 50px);
      }

      100% {
        opacity: 0;
        width: 15%;
        font-size: clamp(15px, 4vw, 20px);
      }
    }

    animation-name: SplashImgGrow;

    animation-duration: $splashScreenAnimTime;
    animation-iteration-count: infinite;

    .teamName {
      font-family: "Oswald", sans-serif;

      text-wrap: nowrap;
    }
  }
}

.teamInfoPage__male {
  .tableContainerWrapper {
    .tableContainer {
      table {
        border: 1px solid $male_mainColor-2;
        background-color: white;

        thead {
          background-color: $male_mainColor-2;
          border-top: 1px solid $male_mainColor-3;
          color: white;

          tr {
            th {
              border: 1px solid $male_mainColor-1;
            }
          }
        }
      }
    }
  }
}

.teamInfoPage__female {
  .tableContainerWrapper {
    .tableContainer {
      table {
        border: 1px solid $female_mainColor-2;
        background-color: white;

        thead {
          background-color: $female_mainColor-2;
          border-top: 1px solid $female_mainColor-3;
          color: white;

          tr {
            th {
              border: 1px solid $female_mainColor-1;
            }
          }
        }
      }
    }
  }
}

.teamInfoPage__white {
  .tableContainerWrapper {
    .tableContainer {
      table {
        border: 1px solid $whiteTourney_mainColor-2;
        background-color: white;

        thead {
          background-color: $whiteTourney_mainColor-2;
          border-top: 1px solid $whiteTourney_mainColor-3;
          color: white;

          tr {
            th {
              border: 1px solid $whiteTourney_mainTextColor-3;
            }
          }
        }
      }
    }
  }

  .moreStatsContainer {
    .moreStats {
      .sectionTitle {
        border-bottom: 3px solid $whiteTourney_mainColor-2;
      }
    }
  }
}
