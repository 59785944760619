.playerStatsRanking {
  margin-top: 30px;
  margin-bottom: 50px;

  width: 100%;
  max-width: 450px;

  .statTitle {
    display: flex;
    justify-content: center;
    gap: 10px;

    text-align: center;
    font-size: 22px;
    font-weight: 500;

    .card {
      box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);

      height: 28px;
      width: 20px;
      border-radius: 3px;
    }

    .card__yellow {
      background-color: rgb(255, 208, 0);
    }

    .card__red {
      background-color: rgb(226, 29, 29);
    }
  }

  .playerStatsTableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .playerStatsTable {
      border-collapse: collapse;
      font-weight: 500;

      thead {
        border-bottom: 1px solid gray;
        font-size: 18px;

        th {
          padding: 8px 0px;
        }

        .teamColumn {
          text-align: start;
          width: 100%;
          padding-left: 25px;
        }

        .pointsColumn {
          padding: 0px 5px;
          max-width: 100px;
        }
      }

      .statsSection {
        &:before {
          content: "@";
          display: block;
          line-height: 10px;
          text-indent: -99999px;
        }

        .playerInfoRow {
          .playerInfoContainer {
            $containerHeight: 85px;

            display: flex;
            align-items: center;

            gap: 10px;

            height: $containerHeight;
            padding: 5px;

            .playerPlacement {
              min-width: 20px;
              padding-left: 5px;
              text-align: center;
              overflow: hidden;
            }

            .imgContainer {
              position: relative;
              display: flex;
              align-items: center;

              .playerImg {
                height: $containerHeight;
                width: $containerHeight;
                border-radius: 5px;
                border: 1px solid lightgray;

                object-fit: cover;
              }

              .creatorOfWebsiteOverlay {
                position: absolute;
                left: 0px;
                top: 0px;
                // z-index: 2;

                background: repeating-linear-gradient(
                  rgba(46, 134, 216, 0.2),
                  rgba(46, 134, 216, 0.2) 4px,
                  rgba(46, 134, 216, 0.5) 4px,
                  rgba(46, 134, 216, 0.5) 6px
                );
                background-size: 100% 9px;
                height: 100%;
                width: 100%;

                animation: pan-overlay 10s infinite linear;
              }

              @keyframes pan-overlay {
                from {
                  background-position: 0% 0%;
                }

                to {
                  background-position: 0% -100%;
                }
              }
            }

            .playerInfo {
              height: 100%;

              font-size: 15px;

              .playerName {
                font-size: 16px;

                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
              }

              .playerTeamContainer {
                display: flex;
                align-items: center;
                gap: 5px;

                color: rgb(107, 107, 107);

                .teamName {
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }

          .playerPoints {
            text-align: center;
            font-size: 20px;
          }
        }
      }
    }

    .showMoreBtn {
      color: #0089bf;
      font-weight: 500;
      font-size: 16px;
    }
  }
}
