@import "../../../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../../../styles-colorLibraries/FemaleTourneyColors.scss";

// Tourney Color Libraries
@import "../../../../styles-colorLibraries/tournaments/green_tourneyColors.scss";
@import "../../../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";
@import "../../../../styles-colorLibraries/tournaments/red_tourneyColors.scss";
@import "../../../../styles-colorLibraries/WhiteTourneyColors.scss";

$height: 50px;
$iconFontSize: 21px;

$animationsSpeed: 300ms;

@mixin pageLink {
  display: grid;
  color: white;
  text-decoration: none;

  height: $height;
  width: 100%;

  .linkIcon {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  i {
    display: flex;
    justify-content: center;

    font-size: 22px;

    width: 100%;

    margin-top: 4px;
    padding-bottom: 4px;
  }

  .material-symbols-rounded {
    text-align: center;
    font-size: 30px;

    width: 100%;
  }

  .linkText {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    height: 15px;

    margin-bottom: 5px;
  }
}

@mixin dropDownBtn {
  display: grid;
  color: white;
  text-decoration: none;

  height: $height;
  width: 100%;

  .linkIcon {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  .fa-solid {
    display: flex;
    justify-content: center;

    font-size: $iconFontSize;

    width: 100%;

    margin-top: 8px;
  }

  .cartTotalItems {
    position: absolute;
    text-align: center;

    color: $mainColor-1;

    width: 20px;

    font-size: 12px;
    font-weight: 700;

    margin-left: 31px;
    margin-top: 7px;
  }

  i {
    display: flex;
    justify-content: center;

    font-size: 18px;

    width: 100%;

    margin-top: 8px;
  }

  .linkText {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;

    height: 15px;

    margin-bottom: 5px;
  }
}

.tournamentMobileFooter {
  display: flex;
  justify-content: space-between;

  position: fixed;
  bottom: 0;

  background-color: $mainColor-1;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.25);

  height: $height;
  width: 100%;

  .activePageLink {
    @include pageLink();
    background-color: $mainColor-2;
  }

  .pageLink {
    @include pageLink();
  }
}

.newTournamentMobileFooter {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%);

  display: flex;
  justify-content: space-between;

  width: calc(100% - 20px);
  padding: 5px 0px;
  padding-bottom: 8px;
  border-radius: 100px;

  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .pageLink,
  .activePageLink {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    color: black;
    text-decoration: none;

    .linkText {
      font-size: 13px;
    }
  }

  .activePageLink {
    .pageLinkIcon {
      color: blue;
    }

    ::after {
      content: "";

      position: absolute;
      bottom: -2px;
      left: 50%;
      transform: translate(-50%);

      height: 2px;
      width: 30px;
      border-radius: 10px;

      background-color: blue;
    }
  }

  &.newTournamentMobileFooter__white {
    .activePageLink {
      .pageLinkIcon {
        color: $whiteTourney_mainColor-2;
      }

      ::after {
        background-color: $whiteTourney_mainColor-2;
      }
    }
  }

  &.newTournamentMobileFooter__purple {
    .activePageLink {
      .pageLinkIcon {
        color: $purple_mainColor-2;
      }

      ::after {
        background-color: $purple_mainColor-2;
      }
    }
  }
}

// =========================================
// =========================================
// =========================================
// =========================================
// =========================================

.tournamentMobileFooter__male {
  background-color: $male_mainColor-1;
  border-top: 1px solid $male_mainColor-1;

  .activePageLink {
    @include pageLink();

    background-color: $male_mainColor-2;
  }

  .pageLink {
    @include pageLink();
  }
}

.tournamentMobileFooter__female {
  background-color: $female_mainColor-1;
  border-top: 1px solid $female_mainColor-1;

  .activePageLink {
    @include pageLink();

    background-color: $female_mainColor-2;
  }

  .pageLink {
    @include pageLink();
  }
}

.tournamentMobileFooter__white {
  background-color: $whiteTourney_mainColor-1;

  .activePageLink {
    @include pageLink();
    background-color: $whiteTourney_mainColor-2;
  }

  .pageLink {
    @include pageLink();
    color: $whiteTourney_mainTextColor-3;
  }
}

.tournamentMobileFooter__green {
  background-color: $green_mainColor-1;

  .activePageLink {
    @include pageLink();
    background-color: $green_mainColor-2;
  }

  .pageLink {
    @include pageLink();
    color: $green_mainTextColor-3;
  }
}

.tournamentMobileFooter__purple {
  background-color: $purple_mainColor-1;

  .activePageLink {
    @include pageLink();
    background-color: $purple_mainColor-2;
  }

  .pageLink {
    @include pageLink();
    color: $purple_mainTextColor-3;
  }
}

.tournamentMobileFooter__red {
  background-color: $red_mainColor-1;

  .activePageLink {
    @include pageLink();
    background-color: $red_mainColor-2;
  }

  .pageLink {
    @include pageLink();
    color: $red_mainTextColor-3;
  }
}

.newTournamentMobileFooter {
  .pageLink,
  .activePageLink {
    .linkText {
      font-size: 13px;
    }
  }

  .activePageLink {
    .pageLinkIcon {
      color: blue;
    }

    ::after {
      background-color: blue;
    }
  }

  &.newTournamentMobileFooter__white {
    .activePageLink {
      .pageLinkIcon {
        color: $whiteTourney_mainColor-2;
      }

      ::after {
        background-color: $whiteTourney_mainColor-2;
      }
    }
  }

  &.newTournamentMobileFooter__purple {
    .activePageLink {
      .pageLinkIcon {
        color: $purple_mainColor-2;
      }

      ::after {
        background-color: $purple_mainColor-2;
      }
    }
  }

  &.newTournamentMobileFooter__red {
    .activePageLink {
      .pageLinkIcon {
        color: $red_mainColor-2;
      }

      ::after {
        background-color: $red_mainColor-2;
      }
    }
  }
}
