@import "../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";

@import "../../styles-colorLibraries/tournaments/red_tourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/green_tourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";

@import "../../styles-commonStyles/skeleton.scss";

.statsPage {
  overflow: hidden;

  .statsPageNavbarContainer {
    display: flex;
    justify-content: center;

    .statsPageNavbar {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;

      border: 1px solid $whiteTourney_mainColor-2;

      height: 60px;
      width: 100%;
      max-width: 450px;
      border-radius: 20px;
      margin: 20px 8px;

      .statsPageNavbarLink {
        display: flex;
        justify-content: center;
        align-items: center;

        color: black;

        text-decoration: none;
        font-size: 20px;
        font-weight: 600;

        height: 93.6%;

        border-bottom: 4px solid transparent;

        &.statsPageNavbarLink__active {
          border-bottom: 4px solid $whiteTourney_mainTextColor-3;
        }
      }
    }
  }

  .statOptionsChipbarContainer {
    display: flex;
    justify-content: center;

    .statOptionsChipbar {
      display: flex;
      gap: 8px;

      padding: 0px 10px;

      overflow-x: auto;

      .statOptionChip {
        background-color: #d4d4d4;
        padding: 8px 10px;
        border-radius: 3px;

        font-weight: 600;

        min-width: fit-content;
      }

      .statOptionChip__active {
        background-color: #0089bf;
        color: white;
      }
    }
  }

  .allPlayerStatsContainer {
    display: flex;
    justify-content: center;

    max-height: fit-content;

    .statsPagePlayerRanking {
      margin-bottom: 50px;

      width: 100%;
      max-width: 450px;

      .statTitle {
        display: flex;
        justify-content: center;
        gap: 10px;

        text-align: center;
        font-size: 28px;
        font-weight: 700;

        .card {
          box-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);

          height: 28px;
          width: 20px;
          border-radius: 3px;
        }

        .card__yellow {
          background-color: rgb(255, 208, 0);
        }

        .card__red {
          background-color: rgb(226, 29, 29);
        }
      }

      .statsSectionWrapper {
        .statsSection {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          padding: 16px;

          .podiumContainer {
            display: flex;
            align-items: flex-end;

            width: calc(100% + 16px);

            .playerPodium {
              position: relative;
              flex: 1;

              .playerImgContainer {
                position: relative;
                bottom: -20px;
                margin-left: auto;
                margin-right: auto;

                display: flex;
                flex-direction: column;
                align-items: center;

                width: 75%;

                .playerImgWrapper {
                  border: 3px solid lightgray;
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

                  width: 100%;
                  aspect-ratio: 1;
                  border-radius: 30px;
                  overflow: hidden;

                  .playerImg {
                    display: flex;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .playerPlacement {
                  position: absolute;
                  bottom: -10px;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  text-align: center;

                  width: 30px;
                  aspect-ratio: 1;
                  border-radius: 7px;

                  font-weight: 700;

                  background-color: lightgray;
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                }
              }

              .playerImgContainer__isMartinArreola {
                .playerImg {
                  scale: 2;
                  animation: pan-image 10s linear infinite;
                }

                .creatorOfWebsiteOverlay {
                  position: absolute;
                  left: 0px;
                  top: 2px;
                  border-radius: 30px;

                  background: repeating-linear-gradient(
                    rgba(46, 134, 216, 0.5),
                    rgba(46, 134, 216, 0.5) 3px,
                    rgba(46, 134, 216, 0.25) 3px,
                    rgba(46, 134, 216, 0.25) 9px
                  );

                  background-size: 100% 9px;
                  height: 97%;
                  width: 100%;

                  animation: pan-overlay 7s infinite linear;
                }

                @keyframes pan-overlay {
                  from {
                    background-position: 0% 0%;
                  }

                  to {
                    background-position: 0% -100%;
                  }
                }

                @keyframes pan-image {
                  0% {
                    transform: translate(25%, -25%);
                  }

                  25% {
                    transform: translate(0%, 15%);
                  }

                  25.0001% {
                    transform: translate(-20%, 25%);
                  }

                  50% {
                    transform: translate(0%, 15%);
                  }

                  50.0001% {
                    transform: translate(0%, -25%);
                  }

                  75% {
                    transform: translate(0%, 15%);
                  }

                  75.0001% {
                    transform: translate(20%, 25%);
                  }

                  100% {
                    transform: translate(0%, 15%);
                  }
                }
              }

              .statsSection {
                display: flex;
                align-items: center;
                text-align: center;
                gap: 10px;

                height: 170px;
                padding: 15px 2px;
                padding-top: calc(20px + 10px + 5px);
                border-radius: 7px;

                background-color: #dfdfdf;
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

                .playerInfo {
                  font-size: 14px;
                  font-weight: 600;

                  @media only screen and (max-width: 400px) {
                    font-size: 13px;
                  }

                  .playerTeam {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  .playerName {
                    font-size: 16px;

                    @media only screen and (max-width: 460px) {
                      font-size: 15px;
                    }

                    @media only screen and (max-width: 360px) {
                      font-size: 14px;
                    }
                  }

                  .playerNickname {
                    color: rgb(112, 112, 112);
                  }
                }

                .playerPointsContainer {
                  font-size: 30px;
                  font-weight: 700;
                  line-height: 1;

                  width: 70px;

                  .statTitleContainer {
                    font-weight: 500;
                    font-size: 16px;
                  }
                }
              }
            }

            .playerPodium:nth-child(1) {
              .playerImgContainer {
                .playerImgWrapper {
                  border-color: #c0c0c0;
                }

                .playerPlacement {
                  background-color: #c0c0c0;
                }
              }
            }

            .playerPodium:nth-child(2) {
              z-index: 1;

              .playerImgContainer {
                .playerImgWrapper {
                  border-color: #ffd700;
                }

                .playerPlacement {
                  background-color: #ffd700;
                }
              }

              .statsSection {
                background-color: #d5d5d5;
                padding-bottom: 40px;
              }
            }

            .playerPodium:nth-child(3) {
              .playerImgContainer {
                .playerImgWrapper {
                  border-color: #cd7f32;
                }

                .playerPlacement {
                  background-color: #cd7f32;
                }
              }
            }

            .teamPodium {
              .statsSection {
                height: 140px;
              }

              .playerImgContainer {
                bottom: -30px;
              }

              .teamPlacement {
                position: absolute;
                bottom: 0;
                width: calc(100% - 16px);
                padding: 4px 0px;
                border-radius: 5px;
                margin-bottom: 8px;

                background-color: lightgray;
              }

              &:nth-child(1) {
                .teamPlacement {
                  background-color: #c0c0c0;
                }
              }

              &:nth-child(2) {
                .teamPlacement {
                  background-color: #ffd700;
                }
              }

              &:nth-child(3) {
                .teamPlacement {
                  background-color: #cd7f32;
                }
              }
            }
          }

          .topPlayerCard {
            position: relative;

            display: flex;
            align-items: center;
            justify-content: space-between;

            border: 1px solid lightgray;

            width: 100%;
            padding: 8px;
            border-radius: 10px;

            overflow: hidden;

            .placementBanner {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              color: white;

              width: 80px;
              aspect-ratio: 1;
              overflow: hidden;

              .background {
                position: absolute;
                top: -50%;
                left: -50%;

                background-color: #c72a2a;
                width: 100%;
                height: 100%;
                transform: rotateZ(-45deg);
              }

              .placement {
                position: absolute;
                top: 0px;
                left: 2px;

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                height: 45%;
                width: 35%;
              }
            }

            .playerInfoSide {
              display: flex;

              .imgContainer {
                display: flex;
                width: 100px;
                aspect-ratio: 1;

                .playerImg {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  object-fit: cover;
                }
              }

              .imgContainer__isMartinArreola {
                position: relative;
                border-radius: 5px;
                overflow: hidden;

                .playerImg {
                  scale: 2;
                  animation: pan-image 10s linear infinite;
                }

                .creatorOfWebsiteOverlay {
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  border-radius: 10px;

                  background: repeating-linear-gradient(
                    rgba(46, 134, 216, 0.5),
                    rgba(46, 134, 216, 0.5) 3px,
                    rgba(46, 134, 216, 0.25) 3px,
                    rgba(46, 134, 216, 0.25) 9px
                  );

                  background-size: 100% 9px;
                  height: 100%;
                  width: 100%;

                  animation: pan-overlay 7s infinite linear;
                }

                @keyframes pan-overlay {
                  from {
                    background-position: 0% 0%;
                  }

                  to {
                    background-position: 0% -100%;
                  }
                }

                @keyframes pan-image {
                  0% {
                    transform: translate(25%, -25%);
                  }

                  25% {
                    transform: translate(0%, 15%);
                  }

                  25.0001% {
                    transform: translate(-20%, 25%);
                  }

                  50% {
                    transform: translate(0%, 15%);
                  }

                  50.0001% {
                    transform: translate(0%, -25%);
                  }

                  75% {
                    transform: translate(0%, 15%);
                  }

                  75.0001% {
                    transform: translate(20%, 25%);
                  }

                  100% {
                    transform: translate(0%, 15%);
                  }
                }
              }

              .playerInfoContainer {
                font-weight: 600;
                margin-left: 5px;

                .playerNickname,
                .yearBorn {
                  font-size: 14px;
                  color: rgb(104, 104, 104);
                }

                .playerTeam {
                  display: flex;
                  align-items: center;

                  margin-top: 10px;
                }
              }
            }

            .playerPointsContainer {
              text-align: center;
              line-height: 1;
              font-weight: 600;

              .playerPoints {
                font-size: 30px;
                font-weight: 700;
              }

              .statTitleContainer {
                width: 60px;

                text-align: center;
                font-size: 14px;
              }
            }
          }

          .teamStatsCard {
            .playerInfoSide {
              display: flex;
              align-items: center;
              gap: 20px;

              font-size: 18px;
              font-weight: 600;

              margin-left: 20px;

              .imgContainer {
                z-index: -1;
                width: 60px;
              }

              .teamName {
              }
            }
          }
        }
      }
    }
  }
}

.statsPage__white {
  .allPlayerStatsContainer {
    .statsPagePlayerRanking {
      .statTitle {
      }

      .statsSectionWrapper {
        .statsSection {
          .topPlayerCard {
            .placementBanner {
              .background {
                background-color: $whiteTourney_mainTextColor-3;
              }

              .placement {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.statsPage__red {
  .statsPageNavbarContainer {
    .statsPageNavbar {
      border: 1px solid $red_mainColor-3;

      .statsPageNavbarLink {
        &.statsPageNavbarLink__active {
          border-bottom: 4px solid $red_mainColor-2;
        }
      }
    }
  }

  .statOptionsChipbarContainer {
    .statOptionsChipbar {
      .statOptionChip__active {
        background-color: $red_mainColor-2;
      }
    }
  }

  .allPlayerStatsContainer {
    .statsPagePlayerRanking {
      .statTitle {
      }

      .statsSectionWrapper {
        .statsSection {
          .topPlayerCard {
            .placementBanner {
              .background {
                background-color: $red_mainColor-2;
              }

              .placement {
                color: white;
              }
            }
          }
        }
      }
    }
  }
}

.statsPage__purple {
  .statsPageNavbarContainer {
    .statsPageNavbar {
      border: 1px solid $purple_mainColor-3;

      .statsPageNavbarLink {
        &.statsPageNavbarLink__active {
          border-bottom: 4px solid $purple_mainColor-2;
        }
      }
    }
  }

  .statOptionsChipbarContainer {
    .statOptionsChipbar {
      .statOptionChip__active {
        background-color: $purple_mainColor-2;
      }
    }
  }

  .allPlayerStatsContainer {
    .statsPagePlayerRanking {
      .statTitle {
      }

      .statsSectionWrapper {
        .statsSection {
          .topPlayerCard {
            .placementBanner {
              .background {
                background-color: $purple_mainColor-2;
              }
            }
          }
        }
      }
    }
  }
}
