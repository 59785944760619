@import "../../../../styles-colorLibraries/MainPagesColors";
@import "../../../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../../../styles-colorLibraries/FemaleTourneyColors.scss";
@import "../../../../styles-colorLibraries/WhiteTourneyColors.scss";
@import "../../../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";
@import "../../../../styles-colorLibraries/tournaments/red_tourneyColors.scss";

$smallSidebarHeight: 100%;
$smallSidebarWidth: 80px;
$iconFontSize: 25px;

@mixin pageLink() {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  color: $mainTextColor-2;

  text-decoration: none;

  padding: 5px 0px;
  width: $smallSidebarWidth/1.1;

  border-radius: 8px;

  margin-bottom: 2px;

  .iconContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkIcon {
      font-size: 20px;
    }

    i {
      font-size: $iconFontSize;
    }

    .material-symbols-outlined {
      font-size: $iconFontSize + 8;
      line-height: 0.8;
    }
  }

  .linkTextContainer {
    display: flex;
    justify-content: center;

    width: 100%;

    .linkText {
      font-size: 10px;
    }
  }
}

.smallSidebar {
  margin-top: 60px;
  padding-top: 5px;

  position: fixed;

  background-color: $mainColor-1;
  color: black;

  border-top: 1px solid transparent;

  height: $smallSidebarHeight;
  width: $smallSidebarWidth;

  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);

  .linksContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .activePageLink {
      @include pageLink();
      background-color: $mainColor-2;
    }

    .pageLink {
      @include pageLink();
    }
    .pageLink:hover {
      background-color: $mainColor-2;
    }
  }
}

.smallSidebar__male {
  background-color: $male_mainColor-1;
  color: white;

  .linksContainer {
    .activePageLink {
      @include pageLink();
      background-color: $male_mainColor-2;
    }

    .pageLink {
      @include pageLink();

      &:hover {
        background-color: $male_mainColor-2;
      }
    }
  }
}

.smallSidebar__female {
  background-color: $female_mainColor-1;
  color: white;

  .linksContainer {
    .activePageLink {
      @include pageLink();
      background-color: $female_mainColor-2;
    }

    .pageLink {
      @include pageLink();

      &:hover {
        background-color: $female_mainColor-2;
      }
    }
  }
}

.smallSidebar__white {
  background-color: $whiteTourney_mainColor-1;
  color: $whiteTourney_mainTextColor-3;

  border-right: 1px solid rgb(236, 236, 236);

  .linksContainer {
    .activePageLink {
      @include pageLink();
      background-color: $whiteTourney_mainColor-2;
      color: white;
    }

    .pageLink {
      @include pageLink();
      color: $whiteTourney_mainTextColor-3;

      &:hover {
        background-color: #04599f44;
      }
    }
  }
}

.smallSidebar__purple {
  background-color: $purple_mainColor-1;
  color: $whiteTourney_mainTextColor-3;

  border-right: 1px solid rgb(236, 236, 236);

  .linksContainer {
    .activePageLink {
      @include pageLink();
      background-color: $purple_mainColor-2;
      color: white;
    }

    .pageLink {
      @include pageLink();
      color: $purple_mainColor-3;

      &:hover {
        background-color: $purple_mainColor-2-hover;
      }
    }
  }
}

.smallSidebar__red {
  background-color: $red_mainColor-1;
  color: $red_mainTextColor-3;

  border-right: 1px solid rgb(236, 236, 236);

  .linksContainer {
    .activePageLink {
      @include pageLink();
      background-color: $red_mainColor-2;
      color: white;
    }

    .pageLink {
      @include pageLink();
      color: $red_mainColor-3;

      &:hover {
        background-color: $red_mainColor-2-hover;
      }
    }
  }
}
