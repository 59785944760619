$screenSize-small: 600px;
$screenSize-medium: 601px;
$screenSize-large: 1200px;

.thePage {
  padding-top: 60px;

  @media only screen and (max-width: $screenSize-small) {
    padding-left: 0px;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: $screenSize-medium) {
    padding-left: 80px;
  }

  @media only screen and (min-width: $screenSize-large) {
    padding-left: 220px;
  }
}

.mobileFooterContainer {
  display: none;

  @media only screen and (max-width: $screenSize-small) {
    display: inline;
  }
}

.mobileFooterContainer {
  display: none;
}

.smallSidebarContainer {
  display: none;
}

.largeSidebarContainer {
  display: none;
}

@media only screen and (max-width: $screenSize-small) {
  .mobileFooterContainer {
    display: inline;
  }

  .smallSidebarContainer {
    display: none;
  }

  .largeSidebarContainer {
    display: none;
  }
}

@media only screen and (min-width: $screenSize-medium) {
  .smallSidebarContainer {
    display: inline;
  }

  .largeSidebarContainer {
    display: none;
  }
}

@media only screen and (min-width: $screenSize-large) {
  .smallSidebarContainer {
    display: none;
  }

  .largeSidebarContainer {
    display: inline;
  }
}
