@import "./NavigationComponents/Tournament/TournamentNavigationComponents.scss";

@import "../../styles-colorLibraries/MixedSexTourneyColors.scss";
@import "../../styles-colorLibraries/MaleTourneyColors.scss";
@import "../../styles-colorLibraries/FemaleTourneyColors.scss";

// Tourney Color Libraries
@import "../../styles-colorLibraries/WhiteTourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/green_tourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/purple_tourneyColors.scss";
@import "../../styles-colorLibraries/tournaments/red_tourneyColors.scss";

$navbarHeight: 60px;
$switchToBigNavbar: 800px;

$sidebarWidth: 230px;
$openSidebarAnimation: 0.4s;
$closeSidebarAnimation: 0.2s;

.tournamentNavbar {
  position: fixed;
  top: 0;
  z-index: 500;

  @media only screen and (min-width: $switchToBigNavbar) {
    justify-content: flex-start;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $mainColor-1;
  color: $mainTextColor-1;
  text-decoration: none;

  height: $navbarHeight;
  width: 100%;

  font-weight: 400;

  @media only screen and (max-width: $screenSize-small) {
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
  }

  @media only screen and (min-width: $screenSize-medium) {
    box-shadow: 84px 5px 8px rgba(0, 0, 0, 0.25);
  }

  @media only screen and (min-width: $screenSize-large) {
    box-shadow: 220px 5px 8px rgba(0, 0, 0, 0.25);
  }

  .hostLogoContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    height: $navbarHeight;
    width: $navbarHeight + 20px;

    .navbarHostLogo {
      height: 100%;
      max-width: $navbarHeight + 30px;
      object-fit: contain;
    }
  }

  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media only screen and (min-width: $switchToBigNavbar) {
      width: auto;
      margin-left: 10px;
    }
    width: 100%;
    text-align: center;
    font-size: 20px;

    margin: 0px 5px;

    z-index: 1;
  }

  .linksContainer {
    @media only screen and (max-width: $switchToBigNavbar) {
      display: none;
    }

    position: absolute;
    right: 10px;

    display: flex;
    gap: 30px;

    .pageLink {
      display: flex;
      align-items: center;
      gap: 5px;

      color: $mainTextColor-1;
      text-decoration: none;

      i {
        font-size: 18px;
      }

      .material-symbols-rounded {
        font-size: 22px;
      }
    }
  }

  .dropDownBtnContainer {
    @media only screen and (min-width: $switchToBigNavbar) {
      display: none;
    }

    display: flex;
    justify-content: flex-end;

    width: $navbarHeight + 20;

    i {
      margin-right: 10px;
      font-size: 28px;
    }

    .material-symbols-rounded {
      margin-right: 5px;
      font-size: 40px;
    }
  }

  .sidebarContainer {
    position: fixed;

    .background {
      position: fixed;
      top: 0;
      right: 0;

      z-index: 500;

      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);

      height: 100%;
      width: 100%;
    }

    .sidebar {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 501;

      background-color: white;
      box-shadow: -2px 0px 8px rgba(0, 0, 0, 0.25);

      height: 100%;
      width: $sidebarWidth;

      .sidebarTitleContainer {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        padding: 20px 0px;

        color: black;

        font-family: "Graduate", serif;
        font-weight: 400;
        font-style: normal;
      }

      .sidebarLinksContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        border-top: 1px solid lightgray;
        padding-top: 5px;

        .profileLink {
          display: flex;
          align-items: center;
          gap: 5px;

          width: $sidebarWidth - 10px;
          padding: 5px 0px;

          color: black;
          text-decoration: none;

          .profilePictureContainer {
            display: flex;
            align-items: center;

            .profilePicture {
              border-radius: 50%;
              width: 40px;
            }
          }

          .profileNameAndUsernameContainer {
            font-weight: 500;
            $textMaxWidth: $sidebarWidth - 10px - 50px - 25px;

            @mixin textEllipsis() {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: $textMaxWidth;
            }

            .fullName {
              @include textEllipsis();
            }

            .username {
              color: gray;
              font-size: 14px;

              @include textEllipsis();

              &::before {
                content: "@";
              }
            }
          }

          .iconContainer {
            display: flex;
            align-items: center;
            margin: auto;
            margin-right: 0;
          }
        }

        .sidebarLink,
        .sidebarLink__active {
          display: flex;
          gap: 5px;

          color: black;
          text-decoration: none;

          width: calc($sidebarWidth - 40px);
          padding: 7px 10px;
          border-radius: 5px;

          font-weight: 500;
          transition: 130ms;

          &:hover {
            background-color: #d0d5db;
          }
        }

        .sidebarLink__active {
          background-color: #d0d5db;
        }
      }
    }

    &.sidebarContainer__active {
      .background {
        @keyframes BackgroundFadeIn {
          0% {
            background-color: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0px);
          }

          100% {
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2px);
          }
        }

        animation-name: BackgroundFadeIn;

        animation-duration: $openSidebarAnimation;
        animation-iteration-count: 1;
      }

      .sidebar {
        @keyframes SidebarAppear {
          0% {
            right: -$sidebarWidth;
          }

          100% {
            right: 0;
          }
        }

        animation-name: SidebarAppear;

        animation-duration: $openSidebarAnimation;
        animation-iteration-count: 1;
      }
    }

    &.sidebarContainer__disabled {
      .background {
        display: none;

        @keyframes BackgroundFadeOut {
          0% {
            display: inline;
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2px);
          }

          100% {
            display: none;
            background-color: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0px);
          }
        }

        animation-name: BackgroundFadeOut;

        animation-duration: $closeSidebarAnimation;
        animation-iteration-count: 1;
      }

      .sidebar {
        right: -$sidebarWidth;

        @keyframes SidebarDisappear {
          0% {
            right: 0;
          }

          100% {
            right: -$sidebarWidth;
          }
        }

        animation-name: SidebarDisappear;

        animation-duration: $closeSidebarAnimation;
        animation-iteration-count: 1;
      }
    }
  }
}

.tournamentNavbar__male {
  background-color: $male_mainColor-1;
  color: white;

  .dropDownBtnContainer {
    color: $male_mainTextColor-1;
  }

  .dropdown {
    background-color: $male_mainColor-1;

    border: 1px solid $male_mainColor-2;
    color: black;

    .dropdownLink {
      color: $male_mainTextColor-1;
      text-decoration: none;

      &:hover {
        background-color: $male_mainColor-2;
      }
    }
  }
}

.tournamentNavbar__female {
  background-color: $female_mainColor-1;
  color: white;

  .dropDownBtnContainer {
    color: $female_mainTextColor-1;
  }

  .dropdown {
    background-color: $female_mainColor-1;

    border: 1px solid $female_mainColor-2;
    color: black;

    .dropdownLink {
      color: $female_mainTextColor-1;
      text-decoration: none;

      &:hover {
        background-color: $female_mainColor-2;
      }
    }
  }
}

.tournamentNavbar__white {
  background-color: $whiteTourney_mainColor-1;
  color: $whiteTourney_mainTextColor-1;

  .linksContainer {
    .pageLink {
      color: $whiteTourney_mainTextColor-3;
    }
  }

  .dropDownBtnContainer {
    color: $whiteTourney_mainTextColor-3;
  }

  .dropdown {
    background-color: $whiteTourney_mainColor-1;

    border: 1px solid rgb(206, 206, 206);
    color: black;

    .dropdownLink {
      color: $whiteTourney_mainTextColor-3;
      text-decoration: none;

      &:hover {
        background-color: $whiteTourney_mainColor-2-hover;
      }
    }
  }
}

.tournamentNavbar__red {
  background-color: $red_mainColor-1;
  color: $red_mainTextColor-1;

  .linksContainer {
    .pageLink {
      color: $red_mainTextColor-3;
    }
  }

  .dropDownBtnContainer {
    color: $red_mainTextColor-3;
  }

  .dropdown {
    background-color: $red_mainColor-1;

    border: 1px solid rgb(206, 206, 206);
    color: black;

    .dropdownLink {
      color: $red_mainTextColor-3;
      text-decoration: none;

      &:hover {
        background-color: $red_mainColor-2-hover;
      }
    }
  }
}

.tournamentNavbar__purple {
  background-color: $purple_mainColor-1;
  color: $purple_mainTextColor-1;

  .linksContainer {
    .pageLink {
      color: $purple_mainTextColor-3;
    }
  }

  .dropDownBtnContainer {
    color: $purple_mainTextColor-3;
  }

  .dropdown {
    background-color: $purple_mainColor-1;

    border: 1px solid rgb(206, 206, 206);
    color: black;

    .dropdownLink {
      color: $purple_mainTextColor-3;
      text-decoration: none;

      &:hover {
        background-color: $purple_mainColor-2-hover;
      }
    }
  }
}

.tournamentNavbar__green {
  background-color: $green_mainColor-1;
  color: $green_mainTextColor-1;

  .linksContainer {
    .pageLink {
      color: $green_mainTextColor-3;
    }
  }

  .dropDownBtnContainer {
    color: $green_mainTextColor-3;
  }

  .dropdown {
    background-color: $green_mainColor-1;

    border: 1px solid rgb(206, 206, 206);
    color: black;

    .dropdownLink {
      color: $green_mainTextColor-3;
      text-decoration: none;

      &:hover {
        background-color: $green_mainColor-2-hover;
      }
    }
  }
}
