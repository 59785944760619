@import "../../../styles-colorLibraries/MainPagesColors.scss";

$navbarHeight: 60px;

.navbar {
  position: fixed;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $mainColor-1;
  color: $mainTextColor-1;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.2);

  height: $navbarHeight;
  width: 100%;

  font-size: 30px;
  font-weight: 300;

  z-index: 99;

  .logoImg {
    max-height: $navbarHeight - 25px;
    margin-left: 8px;
  }

  .profilePictureContainer {
    display: flex;
    display: none;
    justify-content: space-between;
    align-items: center;
    margin-right: 8px;

    height: $navbarHeight;

    .profileImg {
      max-height: $navbarHeight - 23px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid lightgray;
    }
  }
}
